import axios from 'axios';
import store from '../store'
import router from "../router";

class AccountRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    registerUser(user) {
        return axios.post(`${this.basePath}/register`, user);
    }

    getUserByEmail(userEmail) {
        let headers = {'Authorization': store.state.token};
        return axios.get(`${this.basePath}/account/get-user-by-email/${userEmail}`, {headers: headers});
    }

    updateContactUser(user) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/users/partial`, user, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    confirmedEmail(key) {
        return axios.get(`${this.basePath}/activate?key=${key}`);
    }

    resetPassword(email) {
        return axios.post(`${this.basePath}/account/reset-password/init`, email);
    }

    finishResetPassword(password, key) {
        return axios.post(`${this.basePath}/account/reset-password/finish`, {newPassword: password, key: key});
    }

    login(username, password) {
        let body = { username: username, password: password };
        return axios.post(`${this.basePath}/authenticate`, body);
    }

    logout(username) {
        let headers = {'Authorization': store.state.token};
        return axios.get(`${this.basePath}/authenticate/${username}`, {headers: headers});
    }

    getUser() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/account`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getUserDetails(userId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/ext/user-details/user/${userId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new AccountRepository();
