<template>
  <v-card light min-width="30vw" :max-width="($store.state.desktop)? '42vw' : ''" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (creating)? 'Nuevo Contacto' : 'Editando Contacto' }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-form v-model="valid">
        <p v-if="$store.state.desktop" class="text-center">{{ (creating)? 'Crea un nuevo contacto' : 'Editando el contacto' }}</p>
        <v-container fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Nombre" placeholder="Ej. Diana" v-model="firstName" :rules="firstNameRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Apellido" placeholder="Ej. Hoyos" v-model="lastName" :rules="lastNameRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="6" lg="6" xl="6">
              <v-text-field :label="(firstName !== '')? `Cargo de ${firstName}` : 'Cargo'" placeholder="Ej. Director Mercadeo" v-model="profileDescription" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete :items="areas" :label="(firstName !== '')? `Área de trabajo de ${firstName}` : 'Área de trabajo'" v-model="area" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Email" placeholder="Ej. dianahoyos@dianadigital.com" v-model="email" :rules="emailRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Telefono" placeholder="Ej. 3001234567" v-model="phoneNumber" :rules="phoneRules" outlined/>
            </v-col>
            <v-col v-if="creating || contact.client.clientType === 'PERSONA_JURIDICA'" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete label="Empresa Asociada" :items="business" item-text="businessName" item-value="id" placeholder="Ej. Diana Digital" v-model="businessSelected" outlined>
                <template slot="selection" slot-scope="data">{{ getBusinessName(data.item) }}</template>
                <template slot="item" slot-scope="data">{{ getBusinessName(data.item) }}</template>
              </v-autocomplete>
            </v-col>
            <v-col cols="11" sm="11" md="6" lg="6" xl="6">
              <v-autocomplete :items="sellers" item-text="user.firstName" item-value="id" label="Vendedor Asignado" v-model="seller" outlined/>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn v-if="creating" :disabled="!valid" @click="addContact">
              Agregar Contacto <v-icon class="ml-2" small>fa-plus</v-icon>
            </v-btn>
            <v-btn v-else :disabled="!valid" @click="editContact">
              Editar Contacto <v-icon class="ml-2" small>fa-check</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountRepository from '../../../repositories/account';
import ContactRepository from '../../../repositories/contact';
import { mapMutations } from 'vuex';

export default {
  name: "SaveContact",
  props: {
    creating: Boolean,
    contact: Object,
  },
  data() {
    return {
      areas: [
        'Mercadeo y Publicidad',
        'Innovación',
        'Comunicaciones',
        'Recursos Humanos',
        'Gerencia',
        'Comercial',
        'Finanzas',
        'Compras',
        'Producción',
        'Otra',
      ],

      business: [],
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      businessSelected: 0,
      area: '',
      seller: 0,
      sellers: [],
      profileDescription: '',

      // Validation
      valid: false,
      firstNameRules: [
          n => !!n || 'Nombre es obligatorio',
      ],
      lastNameRules: [
          n => !!n || 'Apellido es obligatorio',
      ],
      emailRules: [
          e => !!e || 'Email es obligatorio',
          e => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e) || 'Email inválido',
      ],
      businessRules: [
          b => !!b || 'Empresa es obligatoria',
      ],
      phoneRules: [
          p => !!p || 'Teléfono es obligatorio',
      ]
    };
  },
  watch: {
    email() {
      this.email = this.email.toLowerCase();
    },
  },
  mounted() {
    if(this.creating)
      this.setDefaults();
    else
      this.setDefaultsByEditing();
  },
  methods: {
    setDefaults() {
      this.business = this.$store.state.clients.filter((client) => client.clientType === 'PERSONA_JURIDICA');
      this.businessSelected = this.business[0].id;
      this.area = this.areas[0];
      this.sellers = this.$store.state.contacts.filter((contact) => contact.client.id === 1);
      this.seller = this.sellers.find((seller) => seller.user.id === this.$store.state.currentUser.id).id;
    },
    setDefaultsByEditing() {
      ContactRepository.getSellerFromContact(this.contact.id).then((response) => {
        if(response.status < 400)
          this.seller = response.data.id;
      });
      if(this.contact.client.clientType === 'PERSONA_JURIDICA') {
        this.business = this.$store.state.clients.filter((client) => client.clientType === 'PERSONA_JURIDICA');
        this.businessSelected = this.business.find((client) => client.id === this.contact.client.id).id;
      }

      this.sellers = this.$store.state.contacts.filter((contact) => contact.client.id === 1);
      this.profileDescription = this.contact.profileDescription;
      this.area = this.contact.area;
      this.phoneNumber = this.contact.phoneNumber;
      this.firstName = this.contact.user.firstName;
      this.lastName = this.contact.user.lastName;
      this.email = this.contact.user.email;
    },
    getBusinessName(client) {
      return this.$store.state.businesses.find((business) => business.client.id === client.id).commercialName;
    },
    ...mapMutations(['setContacts']),
    addContact() {
      let contact = {
        area: this.area,
        profileDescription: this.profileDescription,
        phoneNumber: this.phoneNumber,
        client: { id: this.businessSelected },
        seller: { id: this.seller },
      };

      let user = {
        login: this.email,
        email: this.email,
        password: this.processPassword(this.email),
        firstName: this.firstName,
        lastName: this.lastName,
        langKey: 'es'
      };

      AccountRepository.registerUser(user).then((response) => {
        if(response.status < 400) {

          AccountRepository.getUserByEmail(this.email).then((response) => {
            if(response.status < 400) {
              contact.user = { id: response.data.id };

              ContactRepository.addContact(contact).then((secondResponse) => {
                if(secondResponse.status < 400) {
                  alert(`¡${this.firstName} fue creado satisfactoriamente como contacto del cliente!`);
                  ContactRepository.getAllContacts().then((thirdResponse) => { if (thirdResponse.status < 400) this.setContacts(thirdResponse.data); });
                  this.close();
                }
              });
            }
          })
        }
      });
    },
    ...mapMutations(['setContacts']),
    editContact() {
      let contact = {
        id: this.contact.id,
        area: this.area,
        profileDescription: this.profileDescription,
        phoneNumber: this.phoneNumber,
        client: { id: this.businessSelected },
        seller: { id: this.seller },
        user: { id: this.contact.user.id },
      };

      let user = {
        id: this.contact.user.id,
        login: this.contact.user.login,
        password: 'PasswordVacio',
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      };

      AccountRepository.getUserByEmail(this.email).then((response) => {
        if(response.status < 400) {
          if(this.email !== this.contact.user.email && typeof(response.data) !== 'string')
            alert('Correo ya existe.\nIntente con otro correo.');
          else {
            AccountRepository.updateContactUser(user).then((secondResponse) => {
              if(secondResponse.status < 400) {
                ContactRepository.updateContact(contact).then((thirdResponse) => {
                  if(thirdResponse.status < 400) {
                    alert(`¡${this.firstName} fue editado satisfactoriamente como contacto del cliente!`);
                    ContactRepository.getAllContacts().then((lastResponse) => { if (lastResponse.status < 400) this.setContacts(lastResponse.data); });
                    this.close();
                  }
                });
              }
            });
          }
        }
      });
    },
    processPassword(email) {
      return `${email}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}`;
    },
    close() {
      if(this.$store.state.desktop)
        this.$emit('close');
      else
        this.$router.push('/contactos');
    },
  }
}
</script>

<style scoped>

</style>
