<template>
  <v-container class="mt-5" fluid>
    <h1 class="text-center">Contactos</h1>
    <v-row class="my-5" align="center" justify="center">
      <v-col cols="12" lg="10" md="10" sm="12" xl="10">
        <ContactsList @addContact="openAddContact" @editContact="openEditContact"/>
      </v-col>
    </v-row>

    <!-- Overlay Add Contact !-->
    <v-overlay v-model="overlayAddContact">
      <SaveContact :creating="true" @close="closeAddContact"/>
    </v-overlay>

    <!-- Overlay Edit Contact !-->
    <v-overlay v-model="overlayEditContact">
      <SaveContact :creating="false" :contact="selectedContact" @close="closeEditContact"/>
    </v-overlay>
  </v-container>
</template>

<script>
import Repository from '../../repositories/contact';
import { mapMutations } from 'vuex';
import ContactsList from "@/views/Contacts/components/ContactsList";
import SaveContact from "@/views/Contacts/components/SaveContact";
export default {
  name: "ContactsIndex",
  components: {SaveContact, ContactsList},
  data() {
    return {
      overlayAddContact: false,
      overlayEditContact: false,
      selectedContact: null,
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapMutations(['setContacts']),
    updateData() {
      Repository.getAllContacts().then((response) => {
        if(response.status < 400)
          this.setContacts(response.data);
      });
    },
    openAddContact() {
      if(this.$store.state.desktop)
        this.overlayAddContact = true;
      else
        this.$router.push('/agregar-contacto');
    },
    openEditContact(contact) {
      this.selectedContact = contact;
      if(this.$store.state.desktop)
        this.overlayEditContact = true;
      else
        this.$router.push('/editar-contacto');
    },
    closeAddContact() {
      this.overlayAddContact = false;
    },
    closeEditContact() {
      this.overlayEditContact = false;
    }
  },
}
</script>

<style scoped>

</style>
