<template>
  <v-card light min-width="30vw" :max-width="($store.state.desktop)? '42vw' : ''" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (creating)? 'Nuevo Cliente' : `Editando datos de ${getClientName}` }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text style="max-height: 70vh !important;" class="overflow-y-auto pa-5 pa-5" v-if="step === 0">
      <v-overlay v-model="overlayProcessing" opacity="0.3"><v-progress-circular indeterminate color="secondary" size="70"/></v-overlay>
      <v-form v-model="valid">
        <p v-if="$store.state.desktop" class="text-center">{{ (creating)? 'Crea un nuevo cliente' : 'Edita a tu cliente' }}</p>
        <v-container fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-select v-if="creating" label="Tipo de Cliente" :items="$store.state.constants.clientTypes" v-model="clientType" @change="changeClientType" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-select v-if="creating" :disabled="(clientType === 'PERSONA_JURIDICA')" label="Tipo de Documento"
                        :items="documentTypes" v-model="documentType" @change="changeDocumentType" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Número de Documento" placeholder="Ej. 11540165487"
                            v-model="documentNumber" :rules="[ documentNumberRule ]" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Email Facturación Electrónica" placeholder="Ej. cliente@gmail.com" v-model="email" :rules="emailRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field v-if="creating" :label="(clientType === 'PERSONA_JURIDICA')? 'Razón Social' : 'Nombre'"
                            :placeholder="(clientType === 'PERSONA_JURIDICA')? 'Ej. Empresa S.A.S.' : 'Ej. Camilo'"
                            v-model="firstName" :rules="nameRules" outlined/>
            </v-col>
            <v-col v-if="clientType === 'PERSONA_JURIDICA'" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Nombre Comercial" v-model="commercialName" :rules="commercialNameRules" outlined/>
            </v-col>
            <v-col v-if="clientType === 'PERSONA_NATURAL'" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Apellido" v-model="lastName" :rules="lastNameRules" outlined/>
            </v-col>
            <v-col v-else cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete v-if="creating" :items="businessSectors" label="Sector de la Empresa" v-model="businessSector" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete :items="departments" label="Departamento" v-model="department" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Ciudad" v-model="city" :rules="cityRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Dirección" placeholder="Ej. Calle 5 #42-30" v-model="address" :rules="addressRules" outlined/>
            </v-col>
            <v-col v-if="creating" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Celular" placeholder="Ej. 3014128754" v-model="phoneNumber" :rules="phoneNumberRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-select :items="creditModes" item-text="text" item-value="value" label="Modalidad de Crédito" v-model="creditMode" outlined/>
            </v-col>
            <v-col v-if="creating" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-select :items="ivaOptions" label="Responsable de IVA" item-text="text" item-value="value" placeholder="Ej. No" v-model="ivaOption" :rules="phoneNumberRules" outlined/>
            </v-col>
            <v-col v-if="creating" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete :items="sellers" item-text="user.firstName" item-value="id" label="Vendedor Asignado" v-model="seller" outlined/>
            </v-col>
            <v-col v-if="creating && clientType === 'PERSONA_NATURAL'" cols="11" sm="11" md="8" lg="6" xl="6">
              <v-select :items="personTypes" label="Tipo de Persona Natural" v-model="personType" outlined/>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn v-if="creating" :disabled="!valid" @click="addClient">
              Crear Cliente <v-icon class="ml-2" small>fa-plus</v-icon>
            </v-btn>
            <v-btn v-else :disabled="!valid" @click="editClient">
              Editar Cliente <v-icon class="ml-2" small>fa-check</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-text style="max-height: 70vh !important;" class="overflow-y-auto pa-5 pa-5" v-else>
      <v-form v-model="validContact">
        <p v-if="$store.state.desktop" class="text-center">Crea el primer contacto de {{ firstName }}</p>
        <v-container fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Nombre de Contacto" v-model="contactFirstName" :rules="contactFirstNameRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-text-field label="Apellido de Contacto" v-model="contactLastName" :rules="contactLastNameRules" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="6" xl="6">
              <v-autocomplete :items="areas" label="Área de Trabajo" v-model="area" outlined/>
            </v-col>
            <v-col cols="11" sm="11" md="8" lg="8" xl="8">
              <v-text-field label="Cargo" placeholder="Ej. Director Mercadeo" v-model="profileDescription" outlined/>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn :disabled="!valid" @click="addContact">
              Crear Contacto <v-icon class="ml-2" small>fa-plus</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountRepository from '../../../repositories/account';
import ClientRepository from '../../../repositories/client';
import BusinessRepository from '../../../repositories/business';
import ContactRepository from '../../../repositories/contact';
import { mapMutations } from 'vuex';

export default {
  name: "SaveClient",
  props: {
    creating: Boolean,
    client: Object,
  },
  data() {
    return {
      // View
      overlayProcessing: false,
      step: 0,

      documentTypes: [
        'NIT',
        'CC',
        'CE',
        'TI',
        'Pasaporte',
      ],
      businessSectors: [
        'Financiero',
        'Salud',
        'Industrial',
        'Servicios',
        'Educación',
        'Farmacéutica',
        'Consumo Masivo',
        'Solidario',
        'Agro',
        'Construcción',
        'Hotelería y Turismo',
        'Otro',
      ],
      departments: [
        'Amazonas',
        'Antioquía',
        'Arauca',
        'Atlántico',
        'Bolívar',
        'Boyacá',
        'Caldas',
        'Caquetá',
        'Casanare',
        'Cauca',
        'Cesar',
        'Chocó',
        'Córdoba',
        'Cundinamarca',
        'Guainía',
        'Guaviare',
        'Huila',
        'La Guajira',
        'Magdalena',
        'Meta',
        'Nariño',
        'Norte de Santander',
        'Putumayo',
        'Quindío',
        'Risaralda',
        'San Andrés y Providencia',
        'Santander',
        'Sucre',
        'Tolima',
        'Valle del Cauca',
        'Vaupés',
        'Vichada',
      ],
      creditModes: [
        { text: 'Ninguna', value: '0' },
        { text: '30 días', value: 'A_30_DIAS' },
        { text: '45 días', value: 'A_45_DIAS' },
        { text: '60 días', value: 'A_60_DIAS' },
        { text: '90 días', value: 'A_90_DIAS' },
      ],
      ivaOptions: [
        { text: 'No responsable de IVA', value: false },
        { text: 'Responsable de IVA', value: true },
      ],
      personTypes: [
        'Emprendedor',
        'Estudiante',
        'Otro',
      ],
      sellers: [],
      areas: [
        'Mercadeo y Publicidad',
        'Innovación',
        'Comunicaciones',
        'Recursos Humanos',
        'Gerencia',
        'Comercial',
        'Finanzas',
        'Compras',
        'Producción',
        'Otra',
      ],

      // Model
      clientType: '',
      documentType: '',
      documentNumber: '',
      email: '',
      firstName: '',
      lastName: '',
      commercialName: '',
      businessSector: '',
      department: 'Amazonas',
      city: 'Cali',
      address: '',
      phoneNumber: '',
      creditMode: '0',
      ivaOption: true,
      seller: 0,
      personType: '',

      clientCreatedId: 0,
      contactFirstName: '',
      contactLastName: '',
      area: '',
      profileDescription: '',

      // Validation
      valid: false,
      validContact: false,
      /*documentNumberRule: [
        d => !!d || 'Número de documento es obligatorio',
        d => (this.clientType === 'Jurídica' && d.includes('-'))  || 'NIT debe tener dígito de verificación',
      ],*/
      emailRules: [
        e => !!e || 'Email es obligatorio',
        e => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e) || 'Email inválido',
      ],
      nameRules: [
        n => !!n || 'Nombre es obligatorio',
      ],
      lastNameRules: [
        l => !!l || 'Apellido es obligatorio',
      ],
      commercialNameRules: [
        c => !!c || 'Nombre es obligatorio',
      ],
      addressRules: [
        a => !!a || 'Dirección es obligatoria',
      ],
      cityRules: [
        c => !!c || 'Ciudad es obligatoria',
      ],
      phoneNumberRules: [
        p => !!p || 'Celular es obligatorio',
      ],
      contactFirstNameRules: [
        n => !!n || 'Nombre es obligatorio',
      ],
      contactLastNameRules: [
        n => !!n || 'Apellido es obligatorio',
      ],
    };
  },
  computed: {
    getClientName() {
      let clientId = this.client.id;
      if(this.client.clientType === 'PERSONA_JURIDICA')
        return this.$store.state.businesses.find((business) => business.client.id === clientId).businessName;
      let contactFound = this.$store.state.contacts.find((contact) => contact.client.id === clientId);
      return `${contactFound.user.firstName} ${contactFound.user.lastName}`;
    },
  },
  watch: {
    firstName() {
      this.firstName = this.firstName.toUpperCase();
    },
    lastName() {
      this.lastName = this.lastName.toUpperCase();
    },
    commercialName() {
      this.commercialName = this.commercialName.toUpperCase();
    },
    address() {
      this.address = this.address.toUpperCase();
    },
    email() {
      this.email = this.email.toLowerCase();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    documentNumberRule(value) {
      if(value && (this.documentType !== 'NIT' || (value.includes('-') && value.split('-')[1].length === 1)))
        return true;
      else
        return 'Ingrese un número válido de documento';
    },
    init() {
      this.setDefaults();
    },
    setDefaults() {
      if(this.creating) {
        this.sellers = this.$store.state.contacts.filter((contact) => contact.client.id === 1);
        this.clientType = this.$store.state.constants.clientTypes[0];
        this.businessSector = this.businessSectors[0];
        this.documentType = this.documentTypes[1];
        this.ivaOption = this.ivaOptions[0];
        this.seller = this.sellers.find((seller) => seller.user.id === this.$store.state.currentUser.id).id;
        this.personType = this.personTypes[0];
        this.area = this.areas[0];
      }
      else {
        this.documentNumber = this.client.identificationNumber;
        this.email = this.client.invoicingEmail;
        this.address = this.client.address;
        this.department = this.client.state;
        this.city = this.client.city;
        this.creditMode = (this.client.creditTerm !== null)? this.client.creditTerm : '0';
      }
    },
    changeClientType() {
      if(this.clientType === 'PERSONA_JURIDICA')
        this.documentType = 'NIT';
      else
        this.documentType = 'CC';
    },
    changeDocumentType() {
      if(this.clientType === 'PERSONA_JURIDICA' && this.documentType !== 'NIT') {
        alert('Personas jurídicas sólo se registran con NIT');
        this.documentType = 'NIT';
      }
    },
    ...mapMutations(['setClients']),
    editClient() {
      let updatedClient = {
        id: this.client.id,
        clientType: this.client.clientType,
        invoicingEmail: this.email,
        identificationType: this.client.identificationType,
        identificationNumber: this.documentNumber,
        state: this.department,
        city: this.city,
        address: this.address,
        vatResponsible: this.client.vatResponsible,
      };

      if(this.creditMode !== '0') {
        updatedClient.creditTerm = this.creditMode;
        updatedClient.approvedCredit = false;
      }

      ClientRepository.updateClient(updatedClient).then((response) => {
        if(response.status < 400) {
          alert('¡Cliente fue modificado satisfactoriamente!');
          ClientRepository.getAllClients().then((secondReponse) => { if (secondReponse.status < 400) this.setClients(secondReponse.data)});
          this.close();
        }
      });
    },
    addClient() {
      ClientRepository.existsClientByIdentificationNumber(this.documentNumber).then((response) => {
        if(response.status < 400 && response.data)
          alert('Ya existe un cliente con este número de documento.\nPor favor intenta con otro cliente.');
        else {
          this.overlayProcessing = true;
          if(this.clientType === 'PERSONA_NATURAL')
            this.createPersonalClient();
          else
            this.createBusinessClient();
        }
      });
    },
    ...mapMutations(['setClients', 'setContacts']),
    createPersonalClient() {
      let client = {
        invoicingEmail: this.email,
        address: this.address,
        state: this.department,
        city: this.city,
        identificationNumber: this.documentNumber,
        identificationType: this.documentType.toUpperCase(),
        clientType: this.clientType,
        vatResponsible: this.ivaOption,
      };

      if(this.creditMode !== '0') {
        client.creditTerm = this.creditMode;
        client.approvedCredit = false;
      }

      let contact = {
        area: 'Otra',
        profileDescription: this.personType,
        phoneNumber: this.phoneNumber,
        seller: { id: this.seller },
      };

      let user = {
        login: this.email,
        email: this.email,
        password: this.processPassword(this.email),
        firstName: this.firstName,
        lastName: this.lastName,
        langKey: 'es'
      };

      AccountRepository.registerUser(user).then((response) => {
        if(response.status < 400) {

          AccountRepository.getUserByEmail(this.email).then((response) => {
            if(response.status < 400) {
              contact.user = {id: response.data.id};

              ClientRepository.addClient(client).then((secondResponse) => {
                if(secondResponse.status < 400) {
                  contact.client = { id: secondResponse.data.id };

                  ContactRepository.addContact(contact).then(async (secondResponse) => {
                    if(secondResponse.status < 400) {
                      alert(`¡${this.firstName} fue creado satisfactoriamente como cliente!`);
                      await ContactRepository.getAllContacts().then((thirdResponse) => { if (thirdResponse.status < 400) this.setContacts(thirdResponse.data); });
                      await ClientRepository.getAllClients().then((thirdResponse) => { if (thirdResponse.status < 400) this.setClients(thirdResponse.data); });
                      this.overlayProcessing = false;
                      this.close();
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    ...mapMutations(['setClients', 'setBusinesses']),
    createBusinessClient() {
      let business = {
        businessName: this.firstName,
        commercialName: this.commercialName,
        sector: this.businessSector,
      };

      let client = {
        invoicingEmail: this.email,
        address: this.address,
        state: this.department,
        city: this.city,
        identificationNumber: this.documentNumber,
        identificationType: this.documentType.toUpperCase(),
        clientType: this.clientType,
        vatResponsible: this.ivaOption,
      };

      if(this.creditMode !== '0') {
        client.creditTerm = this.creditMode;
        client.approvedCredit = false;
      }

      ClientRepository.addClient(client).then((response) => {
        if(response.status < 400) {
          this.clientCreatedId = response.data.id;
          business.client = { id: response.data.id };

          BusinessRepository.addBusiness(business).then(async (response) => {
            if(response.status < 400) {
              alert(`¡La empresa ${this.firstName} fue creada satisfactoriamente!`);
              await BusinessRepository.getAllBusinesses().then((thirdResponse) => { if (thirdResponse.status < 400) this.setBusinesses(thirdResponse.data); });
              await ClientRepository.getAllClients().then((thirdResponse) => { if (thirdResponse.status < 400) this.setClients(thirdResponse.data); });
              this.overlayProcessing = false;
              this.step = 1;
            }
          });
        }
      });
    },
    ...mapMutations(['setContacts']),
    addContact() {
      let contact = {
        area: this.area,
        profileDescription: this.profileDescription,
        phoneNumber: this.phoneNumber,
        client: { id: this.clientCreatedId },
        seller: { id: this.seller },
      };

      let user = {
        login: this.email,
        email: this.email,
        password: this.processPassword(this.email),
        firstName: this.contactFirstName,
        lastName: this.contactLastName,
        langKey: 'es'
      };

      AccountRepository.registerUser(user).then((response) => {
        if(response.status < 400) {

          AccountRepository.getUserByEmail(this.email).then((response) => {
            if(response.status < 400) {
              contact.user = {id: response.data.id};

              ContactRepository.addContact(contact).then((secondResponse) => {
                if(secondResponse.status < 400) {
                  alert(`¡${this.firstName} fue creado satisfactoriamente como contacto del cliente!`);
                  ContactRepository.getAllContacts().then((thirdResponse) => { if (thirdResponse.status < 400) this.setContacts(thirdResponse.data); });
                  this.close();
                }
              });
            }
          })
        }
      });
    },
    processPassword(email) {
      return `${email}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}`;
    },
    close() {
      if(this.$store.state.desktop)
        this.$emit('close');
      else
        this.$router.push('/clientes');
    },
  }
}
</script>

<style scoped>

</style>
