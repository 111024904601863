import axios from 'axios';
import store from '../store'
import router from "../router";

class ProductRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllStandardizedProducts() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/standardized-products`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getAllOrderProductsFromOrder(orderId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/products/from-order/${orderId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addStandardizedProduct(standardizedProduct) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/standardized-products`, standardizedProduct, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addOrderProduct(product) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/products`, product, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateStandardizedProduct(standardizedProduct) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/standardized-products`, standardizedProduct, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateOrderProduct(product) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/products`, product, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    duplicateOrderProduct(productId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/products/duplicate/${productId}`, null, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteStandardizedProduct(productId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/standardized-products/${productId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteOrderProduct(productId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/products/${productId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    // Items related
    assignItemsToStandardizedProduct(itemsData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/standardized-products/assign`, itemsData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    assignItemsToOrderProduct(itemsData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/products/assign`, itemsData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getItemsFromStandardizedProduct(standardizedProductId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/standardized-products/get-items/${standardizedProductId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getItemsFromOrderProduct(orderProductId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/products/get-items/${orderProductId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addFreePriceItem(freePriceItem) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/free-price-items`, freePriceItem, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getAllFreePriceItemsByProduct(productId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/free-price-items/product/${productId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new ProductRepository();
