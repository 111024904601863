import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        backend: process.env.VUE_APP_BACKEND_URL,
        desktop: false,
        token: '',
        currentUser: {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            rol: '',
            imageUrl: '',
        },
        orders: [],
        clients: [],
        businesses: [],
        contacts: [],
        standardizedProducts: [],
        items: [],
        constants: {
            clientTypes: [
                'PERSONA_NATURAL',
                'PERSONA_JURIDICA',
            ],
            cycles: [
                'COMERCIAL',
                'OPERACIONAL',
                'CONTABLE',
            ],
            measureScales: [
                'UNIDADES',
                'CMS_CUADRADOS',
                'CMS_LINEALES',
            ],
            categories: [
                'IMPRESION',
                'RIGIDO',
                'PAPEL',
                'SUSTRATO',
                'ACCESORIO',
                'INSTALACION',
                'TERMINADO'
            ],
            commercialStates: [
                'INICIADA',
                'PRESENTADA',
                'RECHAZADA',
                'APROBADA',
            ],
            operationalStates: [
                'REVISION_ARCHIVOS',
                'LIBERADA',
                'EN_DESPACHO',
                'ENTREGADA',
            ],
            accountableStates: [
                'ABONADA',
                'PENDIENTE_FACTURAR',
                'REMISIONADA',
                'FACTURADA',
            ],
            deliveryTimes: [
                'A_CONVENIR',
                'EN_2_DIAS',
                'EN_5_DIAS',
                'EN_10_DIAS',
                'EN_15_DIAS',
                'EN_20_DIAS',
                'EN_30_DIAS'
            ],
            months: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ]
        }
    },
    actions: {
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setDesktopContext(state, desktop) {
            state.desktop = desktop;
        },
        setCurrentUser(state, user) {
            state.currentUser.id = user.id;
            state.currentUser.firstName = user.firstName;
            state.currentUser.lastName = user.lastName;
            state.currentUser.email = user.email;
            state.currentUser.rol = user.authorities[0];
            state.currentUser.imageUrl = user.imageUrl;
        },
        setClients(state, clients) {
            state.clients = clients;
        },
        setOrders(state, orders) {
            state.orders = orders;
        },
        setBusinesses(state, businesses) {
            state.businesses = businesses;
        },
        setContacts(state, contacts) {
            state.contacts = contacts;
        },
        setStandardizedProducts(state, standardizedProducts) {
            state.standardizedProducts = standardizedProducts;
        },
        setItems(state, items) {
            state.items = items;
        },
        logoutSession(state) {
            state.token = '';
            state.currentUser.id = 0;
            state.currentUser.firstName = '';
            state.currentUser.lastName = '';
            state.currentUser.email = '';
            state.currentUser.rol = '';
            state.imageUrl = '';
        }
    }
});
