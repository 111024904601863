<template>
  <v-container fluid>
    <v-row style="height: 87vh;" align="center" justify="center">
      <v-card class="pa-5" outlined>
        <h3 class="text-center">Di-Lab Menú</h3>
        <v-btn style="width: 100%" class="my-2" v-bind:key="item.text" v-for="item in items" color="primary" @click="goToRoute(item.route)">
          {{ item.text }}
          <v-icon class="ml-2" small>{{ item.icon }}</v-icon>
        </v-btn>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MobileHomeOptions",
  data() {
    return {
      items: [
        {text: 'Órdenes', icon: 'fa-boxes', route: '/ordenes'},
        {text: 'Clientes', icon: 'fa-user-friends', route: '/clientes'},
        {text: 'Contactos', icon: 'fa-users', route: '/contactos'},
        {text: 'Productos', icon: 'fa-box', route: '/productos'},
        {text: 'Items', icon: 'fa-cube', route: '/items'},
      ],
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
  },
}
</script>

<style scoped>

</style>
