<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Items de {{ (isStandardized)? product.name : product.description }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text style="max-height: 60vh !important;" class="overflow-y-auto pa-5">
      <v-container fluid>
        <v-row v-bind:key="item.id" v-for="item in items" align="center" justify="start" dense>
          <v-chip :color="(item.category === 'IMPRESION')? 'primary' : 'gray'" class="mr-2 mt-n4" small>
            <p style="font-size: 12px;" class="my-0">{{ item.category }}</p>
          </v-chip>
          <p>{{ item.name }}</p>
        </v-row>
        <v-divider v-if="freePriceItems.length > 0" class="mt-2 mb-5"/>
        <v-row v-bind:key="freeItem.id" v-for="freeItem in freePriceItems" align="center" justify="start" dense>
          <v-chip color="accent" text-color="black" class="mr-2 mt-n4" small>
            <p style="font-size: 12px;" class="my-0">ITEM EXTERNO</p>
          </v-chip>
          <p>{{ freeItem.name }} (Precio de ${{ new Intl.NumberFormat().format(Number(freeItem.price.toFixed(0))) }})</p>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/product';

export default {
  name: "ProductItems",
  props: {
    product: Object,
    isStandardized: Boolean,
  },
  data() {
    return {
      items: [],
      freePriceItems: [],
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      let promise = (this.isStandardized)? Repository.getItemsFromStandardizedProduct(this.product.id) : Repository.getItemsFromOrderProduct(this.product.id);
      promise.then((response) => {
        if(response.status < 400) {
          console.log(response.data);
          this.items = response.data;
        }
      });

      if(!this.isStandardized)
        Repository.getAllFreePriceItemsByProduct(this.product.id).then((response) => {
          if(response.status < 400)
            this.freePriceItems = response.data;
        });
    },
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
