<template>
  <v-container class="mt-5" fluid>
    <h1 class="text-center">Productos Estandarizados</h1>
    <v-row class="my-5" align="center" justify="center">
      <v-col cols="12" lg="8" md="8" sm="12" xl="8">
        <ProductsList @addProduct="openAddProduct" @seeItems="openProductItems" @editProduct="openEditProduct" @deleteProduct="openDeleteProduct"/>
      </v-col>
    </v-row>

    <!-- Overlay for Add Product !-->
    <v-overlay v-model="overlayAddProduct">
      <SaveStandardizedProduct :creating="true" @close="closeAddProduct"/>
    </v-overlay>

    <!-- Overlay to See Product Items !-->
    <v-overlay v-model="overlayProductItems">
      <ProductItems :product="selectedProduct" :is-standardized="true" @close="closeProductItems"/>
    </v-overlay>

    <!-- Overlay for Edit Product !-->
    <v-overlay v-model="overlayEditProduct">
      <SaveStandardizedProduct :creating="false" :product="selectedProduct" @close="closeEditProduct"/>
    </v-overlay>

    <!-- Overlay for Delete Product !-->
    <v-overlay v-model="overlayDeleteProduct">
      <DeleteProduct :product="selectedProduct" :is-standardized="true" @close="closeDeleteProduct"/>
    </v-overlay>
  </v-container>
</template>

<script>
import Repository from '../../repositories/product';
import { mapMutations } from 'vuex';

import ProductsList from "@/views/Products/components/ProductsList";
import SaveStandardizedProduct from "@/views/Products/components/SaveStandardizedProduct";
import DeleteProduct from "@/views/Products/components/DeleteProduct";
import ProductItems from "@/views/Products/components/ProductItems";

export default {
  name: "ProductsIndex",
  components: {ProductItems, DeleteProduct, SaveStandardizedProduct, ProductsList},
  data() {
    return {
      selectedProduct: null,
      overlayAddProduct: false,
      overlayProductItems: false,
      overlayEditProduct: false,
      overlayDeleteProduct: false,
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapMutations(['setStandardizedProducts']),
    updateData() {
      Repository.getAllStandardizedProducts().then((response) => {
        if(response.status < 400)
          this.setStandardizedProducts(response.data);
      });
    },
    openAddProduct() {
      if(this.$store.state.desktop)
        this.overlayAddProduct = true;
      else
        this.$router.push('/agregar-producto');
    },
    closeAddProduct() {
      this.overlayAddProduct = false;
    },
    openProductItems(product) {
      this.selectedProduct = product;
      this.overlayProductItems = true;
    },
    closeProductItems() {
      this.overlayProductItems = false;
    },
    openEditProduct(product) {
      this.selectedProduct = product;
      this.overlayEditProduct = true;
    },
    closeEditProduct() {
      this.overlayEditProduct = false;
    },
    openDeleteProduct(product) {
      this.selectedProduct = product;
      this.overlayDeleteProduct = true;
    },
    closeDeleteProduct() {
      this.overlayDeleteProduct = false;
    },
  }
}
</script>

<style scoped>

</style>
