<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h3>Listado de Items</h3>
        <v-btn v-if="fullAccess" class="ml-5" dark small @click="addItem">Agregar
          <v-icon class="ml-2" small>fa-plus</v-icon>
        </v-btn>
        <v-spacer/>
        <v-text-field v-model="search"
                      :placeholder="($store.state.desktop)? 'Busca por id, nombre, escala de medida o categoría' : 'Buscar items'"
                      prepend-icon="fa-search"/>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="$store.state.items" :search="search">
            <template v-slot:item.measureScale="{ item }">
              <p style="font-size: 13px" class="my-0">{{ formatText(item.measureScale) }}</p>
            </template>
            <template v-slot:item.category="{ item }">
              <v-chip text-color="white" :color="getCategoryColor(item.category)" small>
                <p style="font-size: 12px" class="my-0">{{ formatText(item.category) }}</p>
              </v-chip>
            </template>
            <template v-slot:item.unitValue="{ item }">
              <p style="font-size: 14px" class="my-0">{{ getUnitValueText(item) }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-3" small @click="editItem(item)">fa-edit</v-icon>
              <v-icon small @click="deleteItem(item)">fa-trash</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import RoleAccess from "@/specs/roleAccess";

export default {
  name: "ItemsLists",
  data() {
    return {
      fullAccess: false,
      search: '',
      headers: [
        { text: 'ID', value: 'id', align: 'left', sortable: true, filterable: true },
        { text: 'Nombre', value: 'name', align: 'left', sortable: true, filterable: true },
        { text: 'Escala de Medida', value: 'measureScale', align: 'left', sortable: true, filterable: true },
        { text: 'Categoría', value: 'category', align: 'left', sortable: true, filterable: true },
        { text: 'Valor Unitario', value: 'unitValue', align: 'left', sortable: true, filterable: false },
      ]
    };
  },
  mounted() {
    this.verifyFullAccess();
  },
  methods: {
    verifyFullAccess() {
      this.fullAccess = RoleAccess.find((id) => id === this.$store.state.currentUser.id) !== undefined;
      if(this.fullAccess)
        this.headers.push({ text: 'Acciones', value: 'actions', align: 'right', sortable: false, filterable: false });
    },
    addItem() {
      this.$emit('addItem');
    },
    editItem(item) {
      this.$emit('editItem', item);
    },
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    getMeasureScaleColor(measureScale) {
      switch (measureScale) {
        case 'UNIDADES':
          return '#3700b6';
        case 'CMS_CUADRADOS':
          return '#FF1A6C';
        default:
          return '#f14400';
      }
    },
    getCategoryColor(category) {
      switch (category) {
        case 'IMPRESION':
          return '#3700b6';
        case 'RIGIDO':
          return '#FF1A6C';
        case 'TERMINADO':
          return '#208600';
        case 'PAPEL':
          return '#008c9f';
        case 'SUSTRATO':
          return '#9f7200';
        case 'ACCESORIO':
          return '#55009f';
        default:
          return '#f14400';
      }
    },
    getUnitValueText(item) {
      let suffix = (item.measureScale === 'UNIDADES')? 'unidad' :
          (item.measureScale === 'CMS_CUADRADOS')? 'cm2' : 'cm';
      return `$${item.unitValue.toFixed(2)} /${suffix}`;
    },
    formatText(text) {
      return text.replaceAll('_', ' ');
    },
    capitalize(text) {
      return `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}`
    }
  }
}
</script>

<style scoped>

</style>
