<template>
  <v-container class="mt-5" fluid>
    <v-overlay v-model="overlayProcessing"><v-progress-circular indeterminate color="secondary" size="100"/></v-overlay>
    <v-row align="center" class="my-7" justify="center">
      <h3 style="color: #7e7e7e">¡Bienvenido de nuevo {{ $store.state.currentUser.firstName }}!</h3>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10" md="10" sm="12" xl="10">
        <OrdersFunnel/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderRepository from '../repositories/order';
import ClientRepository from '../repositories/client';
import BusinessRepository from '../repositories/business';
import ContactRepository from '../repositories/contact';
import ItemRepository from '../repositories/item';
import ProductRepository from '../repositories/product';
import { mapMutations } from 'vuex';

import OrdersFunnel from "@/components/OrdersFunnel";

export default {
  name: "Inicio",
  components: {OrdersFunnel},
  data() {
    return {
      overlayProcessing: false,
    };
  },
  mounted() {
    this.updateCentralData();
  },
  methods: {
    ...mapMutations(['setOrders', 'setClients', 'setBusinesses', 'setContacts', 'setStandardizedProducts', 'setItems']),
    async updateCentralData() {
      this.overlayProcessing = true;
      await OrderRepository.getAllOrders().then((response) => { if (response.status < 400) this.setOrders(response.data) });
      await ClientRepository.getAllClients().then((response) => { if (response.status < 400) this.setClients(response.data) });
      await BusinessRepository.getAllBusinesses().then((response) => { if (response.status < 400) this.setBusinesses(response.data) });
      await ContactRepository.getAllContacts().then((response) => { if (response.status < 400) this.setContacts(response.data) });
      await ProductRepository.getAllStandardizedProducts().then((response) => { if (response.status < 400) this.setStandardizedProducts(response.data) });
      await ItemRepository.getAllItems().then((response) => { if (response.status < 400) this.setItems(response.data) });
      this.overlayProcessing = false;
    },
  }
}
</script>

<style scoped>

</style>
