import axios from 'axios';
import store from '../store'
import router from "../router";

class OrderRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllOrders() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/orders`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addOrder(order) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/orders`, order, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateOrder(order) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders`, order, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteOrder(orderId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/orders/${orderId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    // Single Processing
    processPricing(orderId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/process-pricing/${orderId}`, null, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editTitle(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-title`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editDeliveryFee(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-delivery-fee`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editDeliveryNote(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-delivery-note`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editWorkTime(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-work-time`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editCreditTerm(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-credit-term`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editBonus(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-bonus`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editDiscount(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-discount`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editCommercialState(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-commercial-state`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editOperationalState(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-operational-state`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    editAccountableState(editData) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/orders/edit-accountable-state`, editData, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new OrderRepository();
