<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (creating)? 'Nuevo Producto Tercerizado' : 'Editando Producto Tercerizado' }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-form v-model="valid">
        <p v-if="$store.state.desktop" class="text-center">Detalles del producto tercerizado</p>
        <v-container fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="10" xl="10">
              <v-textarea label="Descripción del Producto" placeholder="Ej. Gorras en Cuero Premium"
                          v-model="description" :rules="descriptionRules" counter="150" rows="2" auto-grow outlined/>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
              <v-text-field label="Ancho en CMS" type="number" v-model="width" :rules="widthRules" outlined/>
              <p class="mt-5 ml-2">cms</p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
              <v-text-field label="Alto en CMS" type="number" v-model="height" :rules="heightRules" outlined/>
              <p class="mt-5 ml-2">cms</p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
              <v-text-field label="Unidades" type="number" v-model="units" :rules="unitsRules" outlined/>
              <p class="mt-5 ml-2">unds</p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
              <v-text-field label="Costo Unitario" type="number" v-model="unitCost" :rules="unitCostRules" outlined/>
              <p class="mt-5 ml-2">/und</p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
              <v-text-field label="Márgen Unitario (Porcentual)" type="number" v-model="unitMargin" :rules="unitMarginRules" outlined/>
              <p class="mt-5 ml-2">%</p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn v-if="creating" :disabled="!valid" @click="addProduct">
              Agregar Producto <v-icon class="ml-2" small>fa-plus</v-icon>
            </v-btn>
            <v-btn v-else :disabled="!valid" @click="editProduct">
              Editar Producto <v-icon class="ml-2" small>fa-check</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/product';

export default {
  name: "SaveThirdPartyProduct",
  props: {
    creating: Boolean,
    orderId: Number,
    product: Object,
  },
  data() {
    return {
      description: '',
      width: 0,
      height: 0,
      units: 0,
      unitCost: 0,
      unitMargin: 0,

      // Validations
      valid: false,
      descriptionRules: [
        n => !!n || 'Descripción del producto es obligatorio',
        n => (n && n.length <= 150) || 'Descripción debe ser menor a 150 caracteres',
      ],
      widthRules: [
        w => !!w || 'Ancho es obligatorio',
        w => (w && Number(w) > 0)|| 'Ancho debe ser mayor a 0 cms',
      ],
      heightRules: [
        h => !!h || 'Alto es obligatorio',
        h => (h && Number(h) > 0)|| 'Alto debe ser mayor a 0 cms',
      ],
      unitsRules: [
        u => !!u || 'Unidades son requeridas',
        u => (u && Number(u) > 0)|| 'Unidades debe ser mayor a 0',
      ],
      unitCostRules: [
        u => !!u || 'Costo es requerido',
        u => (u && Number(u) > 0)|| 'Costo debe ser mayor a 0',
      ],
      unitMarginRules: [
        u => !!u || 'Márgen es requerido',
        u => (u && Number(u) >= 30)|| 'Márgen debe ser mayor a 30%',
      ],
    };
  },
  mounted() {
    if(!this.creating)
      this.setDefaults();
    else if(this.orderId === undefined)
      this.close();
  },
  methods: {
    setDefaults() {
      this.description = this.product.description;
      this.width = this.product.width;
      this.height = this.product.height;
      this.units = this.product.units;
      this.unitMargin = this.product.margin;
      this.unitCost = (this.product.totalPrice * (1 - (this.unitMargin / 100))) / this.units;
    },
    addProduct() {
      let unitArea = this.width * this.height;
      let totalPrice = (this.unitCost / (1 - (this.unitMargin / 100))) * this.units;
      let product = {
        description: this.description,
        thirdParty: true,
        units: this.units,
        width: this.width,
        height: this.height,
        unitArea: unitArea,
        totalArea: (unitArea) * this.units,
        totalPrice: totalPrice,
        margin: this.unitMargin,
        order: { id: this.orderId },
      };

      Repository.addOrderProduct(product).then((response) => {
        if (response.status < 400) {
          alert(`¡${this.description} fue ${(this.creating)? 'creado' : 'modificado'} satisfactoriamente!`);
          this.productAdded();
          this.close();
        }
      });
    },
    editProduct() {
      Repository.deleteOrderProduct(this.product.id).then((response) => {
        if(response.status < 400)
          this.addProduct();
      });
    },
    productAdded() {
      this.$emit('productAdded');
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.add-item-button {
  background-color: #3700b6 !important;
  color: #ffffff !important;
}
</style>
