<template>
  <v-card class="d-flex align-center justify-center py-1 px-5" color="red lighten-1" @click="goToPending" outlined>
    <p style="color: #ffffff" class="my-0">
      ¡Hey {{$store.state.currentUser.firstName }}! Tienes {{ pendingInvoices.length }} {{ (pendingInvoices.length > 1)? 'órdenes' : 'órden' }} por facturar
    </p>
    <v-icon class="ml-5" color="white" small>fa-bell</v-icon>
  </v-card>
</template>

<script>
export default {
  name: "PendingInvoiceNotifications",
  props: {
    pendingInvoices: Array,
  },
  methods: {
    goToPending() {
      this.$emit('open');
    },
  }
}
</script>

<style scoped>

</style>
