<template>
  <v-app>
    <v-main>
      <SoftwareSummary/>
      <MobileNavBar v-if="!$store.state.desktop && excludedRoutes.indexOf($route.name) === -1"/>
      <SideNavBar v-if="$store.state.desktop && excludedRoutes.indexOf($route.name) === -1"/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import SideNavBar from "@/components/SideNavBar";
import { mapMutations } from 'vuex';
import SoftwareSummary from "@/components/SoftwareSummary";
import MobileNavBar from "@/components/MobileNavBar";

export default {
  name: 'App',
  components: {
    MobileNavBar,
    SoftwareSummary,
    SideNavBar
  },
  data: () => ({
    excludedRoutes: [
        'Login',
        'LostPassword',
    ]
  }),
  mounted() {
    this.verifyAuthenticated();
    this.init();
  },
  methods: {
    verifyAuthenticated() {
      if(this.$store.state.token === '')
        this.$router.push('/');
      else if(this.$route.name === 'Login')
        this.$router.push('/inicio');
    },
    ...mapMutations(['setDesktopContext']),
    init() {
      this.setDesktopContext(screen.width >= 760);
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap');

h1 {
  font: 27px 'Kanit', sans-serif;
}

h2 {
  font: 21px 'Kanit', sans-serif;
}

h3 {
  font: 18px 'Kanit', sans-serif;
}

h4 {
  font: 16px 'Kanit', sans-serif;
}

* {
  font: 15px 'Work Sans', sans-serif;
  text-transform: none !important;
}

button {
  font: 16px 'Kanit', sans-serif;
}

.icon {
  color: #929292 !important;
  border: none !important;
}

.icon:hover {
  color: #929292 !important;
  background-color: rgba(85, 85, 85, 0) !important;
  border: none !important;
}
</style>
