<template>
  <v-container class="background-image" fluid>
    <v-row style="height: 91vh;" align="center" justify="center">
      <v-col cols="12" lg="3" md="8" sm="12" xl="3">
        <v-card class="pa-7 text-center" outlined>
          <v-overlay v-model="overlayProcessing" absolute><v-progress-circular size="70" indeterminate color="primary"/></v-overlay>
          <v-form v-model="valid">
            <div class="d-flex align-center justify-center">
              <v-img contain position="center center" max-width="140"
                     src="https://firebasestorage.googleapis.com/v0/b/di-lab-front.appspot.com/o/Diana%20Digital%20Logo%20Azul.png?alt=media&token=b7207154-2e35-4de1-8a53-e14326602b20"/>
            </div>
            <p class="mb-7 mt-3">Bienvenido a Di-Lab</p>
            <v-text-field label="Email" placeholder="Ej. diana@dianadigital.com" v-model="email" :rules="emailRule" v-on:keypress.enter="login" outlined/>
            <v-text-field label="Contraseña" v-model="password" :type="(showPassword)? 'text' : 'password'" :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                          @click:append="showPassword = !showPassword" :rules="passwordRule" v-on:keypress.enter="login" outlined/>
            <v-btn :disabled="!valid" @click="login">Ingresar »</v-btn>
          </v-form>
          <p style="font-size: 14px;" class="mt-7">
            ¿Olvidó su contraseña? <router-link style="font-size: 14px;" to="/recuperar-cuenta">Haz click aquí</router-link>
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Repository from '../repositories/account';
import { mapMutations } from 'vuex';
export default {
  name: "Login",
  data() {
    return {
      overlayProcessing: false,
      email: '',
      password: '',
      showPassword: false,

      valid: false,
      emailRule: [
        e => !!e || 'Email es obligatorio',
      ],
      passwordRule: [
        p => !!p || 'Contraseña es obligatoria',
      ],
    };
  },
  methods: {
    ...mapMutations(['setToken', 'setCurrentUser']),
    async login() {
      this.overlayProcessing = true;
      Repository.login(this.email, this.password).then(async (response) => {
        await this.setToken(`Bearer ${response.data.id_token}`);
        if(response.status < 250) {
          await Repository.getUser().then((response) => { if(response.status < 400) { this.setCurrentUser(response.data); }});
          this.overlayProcessing = false;
          await this.$router.push('/inicio');
        }
      }).catch(() => {
        alert('Credenciales Inválidas\nIntente con otro par de credenciales.');
        this.overlayProcessing = false;
      });
    },
  }
}
</script>

<style scoped>
.background-image {
  background: url("https://firebasestorage.googleapis.com/v0/b/di-lab-front.appspot.com/o/Background%20Dilab.png?alt=media&token=c80c4752-8394-420d-9993-ca2d6c2d2829") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
