<template>
  <v-card class="d-flex align-center justify-center py-1 px-5" color="red lighten-1" @click="goToApprovals" outlined>
    <p style="color: #ffffff" class="my-0">
      ¡Hey {{$store.state.currentUser.firstName }}! Tienes {{ requests.length }} {{ (requests.length > 1)? 'solicitudes' : 'solicitud' }} de cŕedito {{ (requests.length > 1)? 'pendientes' : 'pendiente'}}
    </p>
    <v-icon class="ml-5" color="white" small>fa-bell</v-icon>
  </v-card>
</template>

<script>
export default {
  name: "CreditApprovalsNotifications",
  props: {
    requests: Array,
  },
  methods: {
    goToApprovals() {
      this.$emit('open');
    },
  }
}
</script>

<style scoped>

</style>
