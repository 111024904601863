<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (creating)? 'Nuevo Item' : 'Editar Item'}}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-form v-model="valid">
        <p v-if="$store.state.desktop" class="text-center">{{ (creating)? 'Crea un nuevo item' : 'Edita un item'}}</p>
        <v-container fluid>
          <v-row dense align="center" justify="center">
            <v-col cols="12" sm="12" md="10" lg="10" xl="10">
              <v-text-field v-model="name" :rules="nameRules" label="Nombre del Item" counter="50" outlined placeholder="Ej. Poliestireno 100"/>
            </v-col>
            <v-col cols="6" sm="6" md="5" lg="5" xl="5">
              <v-select :items="$store.state.constants.categories" v-model="category" :rules="categoryRules"
                        label="Categoría" outlined placeholder="Ej. Impresion"/>
            </v-col>
            <v-col cols="6" sm="6" md="5" lg="5" xl="5">
              <v-select :items="$store.state.constants.measureScales" v-model="measureScale" :rules="measureScaleRules"
                        label="Escala de Medida" outlined placeholder="Ej. Poliestireno 100"/>
            </v-col>
            <v-col cols="6" sm="6" md="5" lg="5" xl="5">
              <v-text-field v-model="unitValue" :rules="unitValueRules" label="Valor Unitario" type="number" outlined placeholder="Ej. Poliestireno 100"/>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn :disabled="!valid" @click="saveItem">
              {{ (creating)? 'Crear Item' : 'Editar Item'}}
              <v-icon class="ml-2" small>{{ (creating)? 'fa-plus' : 'fa-check' }}</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/item';
import { mapMutations } from 'vuex';
export default {
  name: "SaveItem",
  props: {
    creating: Boolean,
    item: Object,
  },
  data() {
    return {
      name: '',
      unitValue: 0,
      category: '',
      measureScale: '',

      // Validations
      valid: false,
      nameRules: [
          n => !!n || 'Nombre es obligatorio',
          n => (n && n.length <= 50) || 'Debe ser menor a 50 caracteres',
      ],
      categoryRules: [
          c => !!c || 'Categoría es obligatoria',
      ],
      measureScaleRules: [
          m => !!m || 'Escala es obligatoria',
      ],
      unitValueRules: [
          v => !!v || 'Valor unitario es obligatorio',
          v => Number(v) > 0 || 'Debe ser mayor a 0',
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if(this.creating) {
        this.category = this.$store.state.constants.categories[0];
        this.measureScale = this.$store.state.constants.measureScales[0];
      }
      else {
        this.name = this.item.name;
        this.unitValue = this.item.unitValue;
        this.category = this.item.category;
        this.measureScale = this.item.measureScale;
      }
    },
    ...mapMutations(['setItems']),
    saveItem() {
      let item = {
        name: this.name,
        unitValue: this.unitValue,
        measureScale: this.measureScale,
        category: this.category,
      };

      if(!this.creating)
        item.id = this.item.id;

      let promise = (this.creating)? Repository.addItem(item) : Repository.updateItem(item);

      promise.then((response) => {
        if(response.status < 400) {
          alert(`¡Item fue ${(this.creating)? 'agregado' : 'modificado'} satisfactoriamente!`);
          Repository.getAllItems().then((secondResponse) => {
            if(secondResponse.status < 400)
              this.setItems(secondResponse.data);
          });
          this.close();
        }
      });
    },
    close() {
      if(this.$store.state.desktop)
        this.$emit('close');
      else
        this.$router.push('/items');
    },
  }
}
</script>

<style scoped>

</style>
