import axios from 'axios';
import store from '../store'
import router from "../router";

class BusinessRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllBusinesses() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/business-details`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addBusiness(business) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/business-details`, business, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateBusiness(business) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/business-details`, business, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteBusiness(businessId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/business-details/${businessId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new BusinessRepository();
