import axios from 'axios';
import store from '../store'
import router from "../router";

class ClientRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllClients() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/client-details`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    existsClientByIdentificationNumber(identificationNumber) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/client-details/by-identification-number/${identificationNumber}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addClient(client) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/client-details`, client, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateClient(client) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/client-details`, client, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteClient(clientId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/client-details/${clientId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    confirmCreditResponse(creditResponse) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/client-details/credit-response`, creditResponse, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new ClientRepository();
