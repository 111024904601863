<template>
  <v-system-bar app class="d-flex justify-center align-center">
    <p class="mt-n1 mb-0 text-center" style="font-size: 14px">Di-Lab by Diana Digital | Versión 1.0.7</p>
  </v-system-bar>
</template>

<script>
export default {
  name: "SoftwareSummary"
}
</script>

<style scoped>

</style>
