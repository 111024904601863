<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h3>Listado de Contactos</h3>
        <v-btn class="ml-5" dark small @click="addContact">Agregar
          <v-icon class="ml-2" small>fa-plus</v-icon>
        </v-btn>
        <v-spacer/>
        <v-text-field v-model="search"
                      :placeholder="($store.state.desktop)? 'Busca por nombres, apellidos o email' : 'Buscar contactos'"
                      prepend-icon="fa-search"/>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-checkbox v-model="seeMyContacts" :label="(seeMyContacts)? 'Viendo mis contactos' : 'Ver únicamente mis contactos'" @change="seeMyContactsChanged"/>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="contactsFiltered" :search="search">
            <template v-slot:item.business="{ item }">
              <p style="font-size: 14px;" class="my-0">{{ getBusinessName(item) }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
              <!--v-icon class="icon" small @click="contactDetails(item)">fa-eye</v-icon!-->
              <v-icon class="icon" small @click="editContact(item)">fa-edit</v-icon>
              <!-- v-icon class="icon" small @click="clientDetails(item)">fa-trash</v-icon !-->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/contact';
export default {
  name: "ContactsList",
  data() {
    return {
      seeMyContacts: false,
      myContacts: [],
      search: '',
      contactsFiltered: [],
      headers: [
        { text: 'Nombres', value: 'user.firstName', align: 'left', sortable: true, filterable: true },
        { text: 'Apellidos', value: 'user.lastName', align: 'left', sortable: true, filterable: true },
        { text: 'Empresa', value: 'business', align: 'left', sortable: true, filterable: false },
        { text: 'Cargo', value: 'profileDescription', align: 'left', sortable: false, filterable: false },
        { text: 'Email', value: 'user.email', align: 'left', sortable: true, filterable: true },
        //{ text: 'Vendedor', value: 'seller.user.firstName', align: 'left', sortable: true, filterable: true },
        { text: 'Teléfono', value: 'phoneNumber', align: 'left', sortable: true, filterable: true },
        { text: 'Acciones', value: 'actions', align: 'right', sortable: false, filterable: false },
      ]
    };
  },
  watch: {
    '$store.state.contacts': function () {
      this.setDefaults();
    }
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.contactsFiltered = this.$store.state.contacts;
      let contactFound = this.$store.state.contacts.find((contact) => contact.user.id === this.$store.state.currentUser.id);
      if(contactFound !== undefined)
        Repository.getAllContactsFromSeller(contactFound.id).then((response) => {
          if(response.status < 400)
            this.myContacts = response.data;
        });
    },
    getBusinessName(contact) {
      let businessFound = this.$store.state.businesses.find((business) => business.client.id === contact.client.id);
      return (businessFound !== undefined)? businessFound.commercialName : '';
    },
    seeMyContactsChanged() {
      this.contactsFiltered = (this.seeMyContacts)? this.myContacts : this.$store.state.contacts;
    },
    contactDetails() {

    },
    editContact(contact) {
      this.$emit('editContact', contact);
    },
    addContact() {
      this.$emit('addContact');
    },
  }
}
</script>

<style scoped>

</style>
