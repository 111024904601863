<template>
  <v-card :max-width="($store.state.desktop)? '25vw' : ''" class="pa-7" light outlined>
    <v-container fluid>
      <v-row>
        <v-col>
          <p class="text-center">Selecciona un tipo de producto</p>
          <v-btn v-for="type in types" v-bind:key="type" class="my-2" color="primary" style="width: 100%"
                 @click="typeChoose(type)">{{ type }}
          </v-btn>
          <v-btn class="mt-2" style="width: 100%" @click="close">Cerrar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ProductTypeSelection",
  data() {
    return {
      types: [
        'Llamar Producto Estandarizado',
        'Crear Producto Personalizado',
        'Crear Producto Tercerizado',
      ]
    };
  },
  methods: {
    typeChoose(type) {
      this.$emit('typeChoose', type);
    },
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>
