<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Vendedores de {{ getClientName }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="overflow-y-auto pa-5" style="max-height: 60vh !important;">
      <v-container fluid>
        <v-row v-for="sellerPair in sellerContactPair" v-bind:key="sellerPair.contact.id" align="center" dense justify="start">
          <v-col>
            <h4><v-icon small class="mr-2 mt-n1">fa-user-tie</v-icon>{{ sellerPair.seller.user.firstName }} {{ sellerPair.seller.user.lastName }}</h4>
            <p>Vendedor/a del contacto {{ sellerPair.contact.user.firstName }} {{ sellerPair.contact.user.lastName }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/contact';
export default {
  name: "ClientSellers",
  props: {
    client: Object,
  },
  data() {
    return{
      contacts: [],
      sellerContactPair: [],
    };
  },
  computed: {
    getClientName() {
      return 'Cliente';
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Repository.getAllSellersFromClient(this.client.id).then((response) => {
        if(response.status < 400) {
          this.sellerContactPair = response.data;
          this.processSellers();
        }
      });
    },
    processSellers() {
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
