<template>
  <v-container class="mt-5" fluid>
    <h1 class="text-center">Órdenes</h1>
    <v-row v-if="pendingToInvoice.length > 0" class="my-5" align="center" justify="center">
      <PendingInvoiceNotifications @open="openPendingInvoice" :pending-invoices="pendingToInvoice"/>
    </v-row>
    <v-row class="my-5" align="center" justify="center">
      <v-col cols="12" lg="11" md="11" sm="12" xl="11">
        <OrdersList @addOrder="openAddOrder" @orderDetails="openOrderDetails" @duplicateOrder="openDuplicateOrder" @deleteOrder="openDeleteOrder"/>
      </v-col>
    </v-row>

    <!-- Overlay Pending Invoice !-->
    <v-overlay v-model="overlayPendingInvoice">
      <PendingInvoices @close="closePendingInvoice" :pending-invoices="pendingToInvoice"/>
    </v-overlay>

    <!-- Overlay Add Order !-->
    <v-overlay v-model="overlayAddOrder">
      <AddOrder @addedOrder="triggerKeepEditingOrder" @close="closeAddOrder"/>
    </v-overlay>

    <!-- Overlay Order Details !-->
    <v-overlay v-model="overlayOrderDetails">
      <EditOrder :order="orderSelected" @close="closeOrderDetails"/>
    </v-overlay>

    <!-- Overlay Duplicate Order !-->
    <v-overlay v-model="overlayDuplicateOrder">
      <DuplicateOrder :order="orderSelected" @close="closeDuplicateOrder"/>
    </v-overlay>

    <!-- Overlay Delete Order !-->
    <v-overlay v-model="overlayDeleteOrder">
      <DeleteOrder :order="orderSelected" @close="closeDeleteOrder"/>
    </v-overlay>
  </v-container>
</template>

<script>
import Repository from '../../repositories/order';
import { mapMutations } from 'vuex';

import OrdersList from "@/views/Orders/components/OrdersList";
import AddOrder from "@/views/Orders/components/AddOrder";
import PendingInvoiceNotifications from "@/views/Orders/components/PendingInvoiceNotifications";
import EditOrder from "@/views/Orders/components/EditOrder";
import DuplicateOrder from "@/views/Orders/components/DuplicateOrder";
import DeleteOrder from "@/views/Orders/components/DeleteOrder";
import PendingInvoices from "@/views/Orders/components/PendingInvoices";
export default {
  name: "OrdersIndex",
  components: {
    PendingInvoices,
    DeleteOrder, DuplicateOrder, EditOrder, PendingInvoiceNotifications, AddOrder, OrdersList},
  data() {
    return {
      orderSelected: null,
      overlayAddOrder: false,
      overlayOrderDetails: false,
      overlayDuplicateOrder: false,
      overlayDeleteOrder: false,
      overlayPendingInvoice: false,

      pendingToInvoice: [],
    };
  },
  watch: {
    '$store.state.order': function () {
      this.reviewPendingInvoices();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapMutations(['setOrders']),
    updateData() {
      Repository.getAllOrders().then((response) => {
        if(response.status < 400)
          this.setOrders(response.data);
      });
      this.reviewPendingInvoices();
    },
    reviewPendingInvoices() {
      this.pendingToInvoice = this.$store.state.orders.filter((order) => order.accountableState !== null && order.accountableState === 'PENDIENTE_FACTURAR');
    },
    openAddOrder() {
      this.overlayAddOrder = true;
    },
    triggerKeepEditingOrder(orderId) {
      this.orderSelected = this.$store.state.orders.find((order) => order.id === orderId);
      if(this.orderSelected !== undefined)
        this.overlayOrderDetails = true;
    },
    openOrderDetails(order) {
      this.orderSelected = order;
      this.overlayOrderDetails = true;
    },
    openDuplicateOrder(order) {
      this.orderSelected = order;
      this.overlayDuplicateOrder = true;
    },
    openDeleteOrder(order) {
      this.orderSelected = order;
      this.overlayDeleteOrder = true;
    },
    openPendingInvoice() {
      this.overlayPendingInvoice = true;
    },
    closeAddOrder() {
      this.overlayAddOrder = false;
    },
    closeOrderDetails() {
      this.overlayOrderDetails = false;
    },
    closeDuplicateOrder() {
      this.overlayDuplicateOrder = false;
    },
    closeDeleteOrder() {
      this.overlayDeleteOrder = false;
    },
    closePendingInvoice() {
      this.overlayPendingInvoice = false;
    },
  }
}
</script>

<style scoped>

</style>
