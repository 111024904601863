import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme:{
        themes: {
            light: {
                primary: '#3700b6',
                secondary: '#FF1A6C',
                accent: '#FFF500',
                error: '#f14400',
            },
        },
    },
});
