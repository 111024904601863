var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"outlined":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h3',[_vm._v("Listado de clientes")]),_c('v-btn',{staticClass:"ml-5",attrs:{"small":"","dark":""},on:{"click":_vm.addClient}},[_vm._v("Agregar "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fa-plus")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"fa-search","placeholder":(_vm.$store.state.desktop)? 'Busca por nombre, documento o email' : 'Buscar clientes'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.fullClients,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.clientType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.clientType === 'PERSONA_JURIDICA')? 'secondary' : 'primary',"small":""}},[_c('p',{staticClass:"my-0",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.formatText(item.clientType)))])])]}},{key:"item.identificationNumber",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-0",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.identificationType)+" "+_vm._s(item.identificationNumber))])]}},{key:"item.creditTerm",fn:function(ref){
var item = ref.item;
return [(!item.creditTerm)?_c('p',{staticClass:"my-0",staticStyle:{"font-size":"14px"}},[_vm._v("No cuenta")]):_c('div',{staticClass:"d-flex align-center justify-start"},[_c('p',{staticClass:"my-0",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.formatText(item.creditTerm)))]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":(item.approvedCredit)? 'green' : 'orange',"small":""}},[_vm._v(_vm._s((item.approvedCredit)? 'fa-check-circle' : 'fa-exclamation-circle'))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id !== 1)?_c('v-icon',{staticClass:"icon",attrs:{"small":""},on:{"click":function($event){return _vm.clientSellers(item)}}},[_vm._v("fa-users")]):_vm._e(),(item.id !== 1)?_c('v-icon',{staticClass:"icon mx-3",attrs:{"small":""},on:{"click":function($event){return _vm.clientDetails(item)}}},[_vm._v("fa-eye")]):_vm._e(),(item.id !== 1)?_c('v-icon',{staticClass:"icon",attrs:{"small":""},on:{"click":function($event){return _vm.editClient(item)}}},[_vm._v("fa-edit")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }