import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from "@/views/Home";
import ClientsIndex from "@/views/Clients/ClientsIndex";
import OrdersIndex from "@/views/Orders/OrdersIndex";
import LostPassword from "@/views/Account/LostPassword";
import AddClient from "@/views/Clients/components/SaveClient";
import ContactsIndex from "@/views/Contacts/ContactsIndex";
import AddContact from "@/views/Contacts/components/SaveContact";
import ProductsIndex from "@/views/Products/ProductsIndex";
import ItemsIndex from "@/views/Items/ItemsIndex";
import AddProduct from "@/views/Products/components/SaveStandardizedProduct";
import AddItem from "@/views/Items/components/SaveItem";
import MobileHomeOptions from "@/components/MobileHomeOptions";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/inicio',
        name: 'Home',
        component: Home,
    },
    {
        path: '/menu',
        name: 'Menu',
        component: MobileHomeOptions,
    },
    {
        path: '/recuperar-cuenta',
        name: 'LostPassword',
        component: LostPassword,
    },
    {
        path: '/clientes',
        name: 'Clients',
        component: ClientsIndex,
    },
    {
        path: '/agregar-cliente',
        name: 'AddClient',
        component: AddClient,
    },
    {
        path: '/contactos',
        name: 'Contacts',
        component: ContactsIndex,
    },
    {
        path: '/agregar-contacto',
        name: 'AddContact',
        component: AddContact,
    },
    {
        path: '/ordenes',
        name: 'Orders',
        component: OrdersIndex,
    },
    {
        path: '/productos',
        name: 'Products',
        component: ProductsIndex,
    },
    {
        path: '/agregar-producto',
        name: 'AddProduct',
        component: AddProduct,
    },
    {
        path: '/items',
        name: 'Items',
        component: ItemsIndex,
    },
    {
        path: '/agregar-item',
        name: 'AddItem',
        component: AddItem,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
