<template>
  <v-container class="mt-5" fluid>
    <h1 class="text-center">Clientes</h1>
    <v-row v-if="clientsWithCreditRequests.length > 0" class="my-5" align="center" justify="center">
      <CreditApprovalsNotifications @open="openApprovals" :requests="clientsWithCreditRequests"/>
    </v-row>
    <v-row class="my-5" align="center" justify="center">
      <v-col cols="12" lg="11" md="11" sm="12" xl="11">
        <ClientsList @addClient="openAddClient" @clientSellers="openClientSellers" @clientDetails="openClientDetails" @editClient="openEditClient"/>
      </v-col>
    </v-row>

    <!-- Overlay Add Client !-->
    <v-overlay v-model="overlayAddClient">
      <SaveClient :creating="true" @close="closeAddClient"/>
    </v-overlay>

    <!-- Overlay Edit Client !-->
    <v-overlay v-model="overlayEditClient">
      <SaveClient :creating="false" :client="selectedClient" @close="closeEditClient"/>
    </v-overlay>

    <!-- Overlay Client Sellers !-->
    <v-overlay v-model="overlayClientSellers">
      <ClientSellers :client="selectedClient" @close="closeClientSellers"/>
    </v-overlay>

    <!-- Overlay Client Details !-->
    <v-overlay v-model="overlayClientDetails">
      <ClientDetails :client="selectedClient" @close="closeClientDetails"/>
    </v-overlay>

    <!-- Overlay Credit Approvals !-->
    <v-overlay v-model="overlayApprovals">
      <CreditApprovals @close="overlayApprovals = false" :requests="clientsWithCreditRequests"/>
    </v-overlay>
  </v-container>
</template>

<script>
import Repository from '../../repositories/client';
import BusinessRepository from '../../repositories/business';
import { mapMutations } from 'vuex';

import ClientsList from "@/views/Clients/components/ClientsList";
import CreditApprovalsNotifications from "@/views/Clients/components/CreditApprovalsNotification";
import CreditApprovals from "@/views/Clients/components/CreditApprovals";
import SaveClient from "@/views/Clients/components/SaveClient";
import ClientSellers from "@/views/Clients/components/ClientSellers";
import ClientDetails from "@/views/Clients/components/ClientDetails";

export default {
  name: "ClientsIndex",
  components: {ClientDetails, ClientSellers, SaveClient, CreditApprovals, CreditApprovalsNotifications, ClientsList},
  data() {
    return {
      clientsWithCreditRequests: [],
      selectedClient: null,
      overlayApprovals: false,
      overlayAddClient: false,
      overlayClientSellers: false,
      overlayClientDetails: false,
      overlayEditClient: false,
    };
  },
  watch: {
    '$store.state.clients': function () {
      this.reviewCreditRequests();
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapMutations(['setClients', 'setBusinesses']),
    updateData() {
      Repository.getAllClients().then((response) => {
        if(response.status < 400)
          this.setClients(response.data);
      });
      BusinessRepository.getAllBusinesses().then((response) => {
        if (response.status < 400)
          this.setBusinesses(response.data)
      });

      this.reviewCreditRequests();
    },
    reviewCreditRequests() {
      this.clientsWithCreditRequests = this.$store.state.clients.filter((client) => client.approvedCredit !== null && !client.approvedCredit);
    },
    openAddClient() {
      if(this.$store.state.desktop)
        this.overlayAddClient = true;
      else
        this.$router.push('/agregar-cliente');
    },
    openClientSellers(client) {
      this.selectedClient = client;
      this.overlayClientSellers = true;
    },
    openClientDetails(client) {
      this.selectedClient = client;
      this.overlayClientDetails = true;
    },
    openEditClient(client) {
      this.selectedClient = client;
      this.overlayEditClient = true;
    },
    closeAddClient() {
      this.overlayAddClient = false;
    },
    closeClientSellers() {
      this.overlayClientSellers = false;
    },
    closeClientDetails() {
      this.overlayClientDetails = false;
    },
    closeEditClient() {
      this.overlayEditClient = false;
    },
    openApprovals() {
      this.overlayApprovals = true;
    },
  }
}
</script>

<style scoped>

</style>
