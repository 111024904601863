<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h2>Embudo de Órdenes</h2>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="10" xl="10" :class="($store.state.desktop)?'d-flex align-center justify-center' : ''">
          <p :class="($store.state.desktop)? 'mr-2 mt-n2' : ''">Ciclo</p>
          <v-select :style="($store.state.desktop)? 'max-width: 20%;' : ''"
                    :items="$store.state.constants.cycles" v-model="categorySelected" @change="valuesChanged" dense outlined/>
          <p :class="($store.state.desktop)? 'mx-2 mt-n2' : ''">en los últimos</p>
          <v-select :style="($store.state.desktop)? 'max-width: 20%;' : ''"
                    :items="periods" v-model="periodSelected" @change="valuesChanged" dense outlined/>
        </v-col>
      </v-row>

      <v-row class="mt-7 mb-3" align="center" justify="center">
        <v-col cols="12">
          <apexchart type="bar" height="350" :options="funnelChartOptions" :series="funnelSeries"/>
        </v-col>
      </v-row>
      <v-row class="my-2" align="center" justify="center">
        <v-btn @click="$router.push('/ordenes')">Ver Órdenes <v-icon class="ml-2" x-small>fa-boxes</v-icon></v-btn>
      </v-row>
      <v-row align="center" justify="center">
        <p class="text-center">Actualizado hasta {{ updatedDate }}</p>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
export default {
  name: "OrdersFunnel",
  data() {
    return {
      periodSelected: '',
      categorySelected: '',

      lastUpdated: new Date(),
      periods: [
        '7 DÍAS',
        '15 DÍAS',
        '30 DÍAS',
        '60 DÍAS',
        '90 DÍAS',
        'HISTÓRICO',
      ],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],

      funnelSeries: [{
        name: 'Órdenes',
        data: [102, 70, 11, 50,]
      }],
      funnelChartOptions: {
        colors: ['#3700b6'],
        chart: {
          height: 350,
          width: '100%',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return `${value}` ;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: ["Iniciadas", "Presentadas", "Rechazadas", "Aprobadas"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (value) {
              return `${value} órdenes` ;
            }
          }
        },
      }
    };
  },
  computed: {
    updatedDate() {
      return `${this.months[this.lastUpdated.getMonth()]} ${this.lastUpdated.getDate()} del ${this.lastUpdated.getFullYear()}
      a las ${String(this.lastUpdated.getHours()).padStart(2, '0')}:${String(this.lastUpdated.getMinutes()).padStart(2, '0')}`;
    },
  },
  watch: {
    '$store.state.orders': function () {
      this.setDefault();
    },
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.categorySelected = this.$store.state.constants.cycles[0];
      this.periodSelected = this.periods[0];
      this.valuesChanged();
    },
    valuesChanged() {
      if(this.categorySelected === 'OPERACIONAL')
        this.getOperationalStats();
      else if(this.categorySelected === 'COMERCIAL')
        this.getCommercialStats();
      else
        this.getAccountableStats();
    },
    getCommercialStats() {
      let dataUpdated = [0, 0, 0, 0];
      this.$store.state.orders.forEach((order) => {
        let state = order.commercialState;
        dataUpdated[(state === 'INICIADA')? 0 : (state === 'PRESENTADA')? 1 : (state === 'RECHAZADA')? 2 : 3]++;
      });
      this.funnelSeries = [{ name: 'Órdenes', data: dataUpdated }];
      this.updateOptions('#3700b6', ['Iniciadas', 'Presentadas', 'Rechazadas', 'Aprobadas']);
    },
    getOperationalStats() {
      let dataUpdated = [0, 0, 0, 0];
      this.$store.state.orders.forEach((order) => {
        let state = order.operationalState;
        if(state !== null)
          dataUpdated[(state === 'REVISION_ARCHIVOS')? 0 : (state === 'LIBERADA')? 1 : (state === 'EN_DESPACHO')? 2 : 3]++;
      });
      this.funnelSeries = [{ name: 'Órdenes', data: dataUpdated }];
      this.updateOptions('#FF1A6C', ['Revisión Archivos', 'Liberadas', 'En Despacho', 'Entregadas']);
    },
    getAccountableStats() {
      let dataUpdated = [0, 0, 0, 0];
      this.$store.state.orders.forEach((order) => {
        let state = order.accountableState;
        if(state !== null)
          dataUpdated[(state === 'ABONADA')? 0 : (state === 'PENDIENTE_FACTURAR')? 1 : (state === 'REMISIONADA')? 2 : 3]++;
      });
      this.funnelSeries = [{ name: 'Órdenes', data: dataUpdated }];
      this.updateOptions('#FFF500', ['Abonadas', 'Pendientes Facturar', 'Remisionadas', 'Facturadas']);
    },
    updateOptions(color, categories) {
      this.funnelChartOptions = {
        colors: [color],
        chart: {
          height: 350,
          width: '100%',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
        },
        xaxis: {
          categories: categories,
          position: 'top',
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter: function (value) {
              return `${value} órdenes` ;
            }
          }
        },
      };
    },
  },
}
</script>

<style scoped>

</style>
