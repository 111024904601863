<template>
  <v-card :max-width="($store.state.desktop)? '35vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Duplicar Órden</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-container fluid>
        <v-row align="center" class="mb-3" justify="center">
          <v-icon class="mr-7 mt-n4" color="secondary">fa-copy</v-icon>
          <p>
            ¿Desea duplicar la órden<br>
            <b style="color: #FF1A6C">{{ order.title }}</b>?
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="duplicateOrder">Duplicar Órden</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DuplicateOrder",
  props: {
    order: Object,
  },
  methods: {
    duplicateOrder() {
      alert('¡Órden fue duplicada satisfactoriamente!');
      this.close();
    },
    close() {
      this.$emit('close');
    }
  },
}
</script>

<style scoped>

</style>
