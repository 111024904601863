<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h3>Listado de clientes</h3>
        <v-btn @click="addClient" class="ml-5" small dark>Agregar <v-icon class="ml-2" small>fa-plus</v-icon></v-btn>
        <v-spacer/>
        <v-text-field prepend-icon="fa-search" :placeholder="($store.state.desktop)? 'Busca por nombre, documento o email' : 'Buscar clientes'" v-model="search"/>
      </v-row>
      <!--v-row align="center" justify="center" dense>
        <v-checkbox v-model="seeMyClients" :label="(seeMyClients)? 'Viendo mis contactos' : 'Ver únicamente mis contactos'" @change="seeMyClientsChanged"/>
      </v-row!-->
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table :items="fullClients" :headers="headers" :search="search">
            <template v-slot:item.clientType="{ item }">
              <v-chip :color="(item.clientType === 'PERSONA_JURIDICA')? 'secondary' : 'primary'" small>
                <p style="font-size: 12px" class="my-0">{{ formatText(item.clientType) }}</p>
              </v-chip>
            </template>
            <template v-slot:item.identificationNumber="{ item }">
              <p style="font-size: 14px" class="my-0">{{ item.identificationType }} {{ item.identificationNumber }}</p>
            </template>
            <template v-slot:item.creditTerm="{ item }">
              <p v-if="!item.creditTerm" style="font-size: 14px" class="my-0">No cuenta</p>
              <div v-else class="d-flex align-center justify-start">
                <p style="font-size: 14px" class="my-0">{{ formatText(item.creditTerm) }}</p>
                <v-icon class="ml-2" :color="(item.approvedCredit)? 'green' : 'orange'" small>{{ (item.approvedCredit)? 'fa-check-circle' : 'fa-exclamation-circle' }}</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="item.id !== 1"  class="icon" small @click="clientSellers(item)">fa-users</v-icon>
              <v-icon v-if="item.id !== 1"  class="icon mx-3" small @click="clientDetails(item)">fa-eye</v-icon>
              <v-icon v-if="item.id !== 1"  class="icon" small @click="editClient(item)">fa-edit</v-icon>
              <!-- v-icon class="icon" small @click="clientDetails(item)">fa-trash</v-icon !-->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ClientsList",
  data() {
    return {
      selectedItem: null,
      search: '',
      fullClients: [],
      headers: [
        { text: 'Nombre Comercial', value: 'name', align: 'left', sortable: true, filterable: true },
        { text: 'Tipo', value: 'clientType', align: 'left', sortable: true, filterable: true },
        { text: 'Documento', value: 'identificationNumber', align: 'left', sortable: true, filterable: true },
        { text: 'Email Facturación', value: 'invoicingEmail', align: 'left', sortable: true, filterable: true },
        //{ text: 'Teléfono', value: 'phoneNumber', align: 'left', sortable: true, filterable: true },
        //{ text: 'Vendedor', value: 'mainSeller.user.firstName', align: 'left', sortable: true, filterable: true },
        { text: 'Crédito', value: 'creditTerm', align: 'left', sortable: true, filterable: true },
        { text: 'Acciones', value: 'actions', align: 'right', sortable: true, filterable: true },
      ]
    };
  },
  watch: {
    '$store.state.clients': function () {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.fullClients = [];
      this.$store.state.clients.forEach((client) => {
        let partialClient = client;
        let contactFound = this.getClientContact(client);
        partialClient.name = (client.clientType === 'PERSONA_JURIDICA')?
            this.$store.state.businesses.find((business) => business.client.id === client.id).commercialName : `${contactFound.user.firstName} ${contactFound.user.lastName}`;

        if(contactFound !== undefined)
          partialClient.phoneNumber = contactFound.phoneNumber;
        this.fullClients.push(partialClient);
      });
    },
    getClientContact(client) {
      return this.$store.state.contacts.find((contact) => contact.client.id === client.id);
    },
    addClient() {
      this.$emit('addClient');
    },
    clientSellers(client) {
      this.$emit('clientSellers', client);
    },
    clientDetails(item) {
      this.$emit('clientDetails', item);
    },
    editClient(client) {
      this.$emit('editClient', client)
    },
    formatText(text) {
      return text.replaceAll('_', ' ');
    },
  }
}
</script>

<style scoped>

</style>
