import axios from 'axios';
import store from '../store'
import router from "../router";

class ItemRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllItems() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/items`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addItem(item) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/items`, item, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateItem(item) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/items`, item, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteItem(itemId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/items/${itemId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new ItemRepository();
