<template>
  <v-card id="pdfTarget" :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (title === '')? 'Nueva Órden' : formatText(`Órden ${title}`, 45) }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text style="max-height: 60vh !important;" class="overflow-y-auto pa-5">
      <v-overlay v-model="overlayProcessing" opacity="0.3"><v-progress-circular indeterminate color="secondary" size="70"/></v-overlay>
      <v-form v-model="valid">
        <p v-if="$store.state.desktop" class="text-center">Crea una nueva órden</p>
        <v-container fluid>
          <v-row align="center" dense justify="center">
            <v-col cols="12" lg="10" md="10" sm="12" xl="10">
              <v-text-field v-model="title" :rules="titleRules" label="Título de la Órden"
                            placeholder="Ej. Juego de Cartas + Caja Impresa" counter="50" outlined/>
            </v-col>
            <v-col cols="6" lg="5" md="5" sm="6" xl="5">
              <v-autocomplete v-model="client" :items="clients" item-text="name" item-value="id" :rules="rules"
                              label="Cliente" placeholder="Ej. Diana Digital" @change="clientChanged" outlined>
                <template v-slot:selection="data">
                  <v-chip v-if="data.item.id === -1" small>{{ data.item.name }}</v-chip>
                  <p v-else class="my-0">{{ data.item.name }}</p>
                </template>
                <template v-slot:item="data">
                  <v-chip v-if="data.item.id === -1" small>{{ data.item.name }}</v-chip>
                  <p v-else class="my-0">{{ data.item.name }}</p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col v-if="isClientABusiness" cols="6" lg="5" md="5" sm="6" xl="5">
              <v-select :items="contacts" v-model="contact" label="Contacto" item-value="id" item-text="user.firstName" :rules="rules" outlined/>
            </v-col>
            <v-col cols="6" lg="5" md="5" sm="6" xl="5">
              <v-autocomplete v-model="seller" :items="sellers" item-text="user.firstName" item-value="id" :rules="rules"
                        label="Vendedor"  placeholder="Ej. Alberto" outlined/>
            </v-col>
            <v-col cols="6" lg="5" md="5" sm="6" xl="5">
              <v-select :items="paymentTypes" v-model="paymentType" label="Forma de Pago" :rules="rules" outlined/>
            </v-col>
            <v-col cols="6" lg="5" md="5" sm="6" xl="5">
              <v-select :items="$store.state.constants.deliveryTimes" v-model="deliveryTime" label="Tiempo de Entrega" :rules="rules" outlined/>
            </v-col>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10">
              <v-textarea v-model="deliveryNote" label="Consideraciones de la Entrega (Opcional)"
                          placeholder="Ej. Enviar a Cl 5 #20-20" rows="1" auto-grow counter="200" outlined/>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn :disabled="!valid" @click="addOrder">Guardar Detalles y Continuar »</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <!-- Overlay new client !-->
    <v-overlay v-model="overlayAddClient">
      <AddClient :creating="true" @close="closeAddClient"/>
    </v-overlay>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/order';
import { mapMutations } from 'vuex';

// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
import AddClient from "@/views/Clients/components/SaveClient";

export default {
  name: "AddOrder",
  components: {AddClient},
  data() {
    return {
      overlayProcessing: false,
      clients: [],
      client: 0,
      seller: 0,
      sellers: [],
      paymentTypes: [],
      paymentType: '',
      title: '',
      validUntil: 0,
      deliveryTime: 'A_CONVENIR',
      contact: 0,
      contacts: [],
      deliveryNote: '',

      // Client
      overlayAddClient: false,

      // Validation
      valid: false,
      titleRules: [
        t => !!t || 'Título Es obligatorio',
        t => (t && t.length <= 50) || 'Debe ser menor a 50 caracteres',
      ],
      rules: [
        r => !!r || 'Es obligatorio',
      ],
    };
  },
  watch: {
    '$store.state.clients': function () {
      this.setDefaults();
    },
  },
  computed: {
    isClientABusiness() {
      let foundClient = this.$store.state.clients.find((client) => client.id === this.client);
      if(foundClient !== undefined)
        return foundClient.clientType === 'PERSONA_JURIDICA';
      return false;
    },
  },
  mounted() {
    this.setDefaults();
    this.savePDF();
  },
  methods: {
    setDefaults() {
      this.updateClientsData();
      this.updatePaymentType();
      //this.clients.push.apply(this.clients, this.$store.state.clients);
      this.sellers = this.$store.state.contacts.filter((contact) => contact.client.id === 1);
      this.seller = this.sellers.find((seller) => seller.user.id === this.$store.state.currentUser.id).id;
      this.deliveryTime = this.$store.state.constants.deliveryTimes[0];
      this.client = this.$store.state.clients[0].id;
      this.paymentType = this.paymentTypes[0];
      this.clientChanged();
    },
    updateClientsData() {
      this.clients = [ { id: -1, name: 'Nuevo Cliente +' } ];
      this.$store.state.clients.forEach((client) => {
        let partialClient = client;
        let contactFound = this.getClientContact(client);
        partialClient.name = (client.clientType === 'PERSONA_JURIDICA')?
            this.$store.state.businesses.find((business) => business.client.id === client.id).commercialName : `${contactFound.user.firstName} ${contactFound.user.lastName}`;

        this.clients.push(partialClient);
      });
    },
    getClientContact(client) {
      return this.$store.state.contacts.find((contact) => contact.client.id === client.id);
    },
    updatePaymentType() {
      this.paymentTypes = ['CONTADO'];
      let clientFound = this.$store.state.clients.find((client) => client.id === this.client);
      if(clientFound !== undefined && clientFound.creditTerm !== null && clientFound.approvedCredit)
        this.paymentTypes.push(clientFound.creditTerm);
      this.paymentType = this.paymentTypes[0];
    },
    clientChanged() {
      if(this.client === -1)
        this.overlayAddClient = true;
      else {
        this.contacts = this.$store.state.contacts.filter((contact) => contact.client.id === this.client);
        if (this.contacts.length > 0)
          this.contact = this.contacts[0].id;
        this.updatePaymentType();
      }
    },
    savePDF() {
      //let doc = new jsPDF();
      //console.log(document.getElementById('pdfTarget'));
      //doc.html(document.getElementById('pdfTarget'), {});
      //doc.save('Prueba PDF.pdf');
    },
    ...mapMutations(['setOrders']),
    addOrder() {
      this.overlayProcessing = true;
      let validTime = new Date;
      validTime.setDate(validTime.getDate() + 30);
      let order = {
        title: this.title,
        wasRequoted: false,
        wasDuplicated: false,
        createdAt: new Date(),
        validUntil: validTime,
        workTime: this.deliveryTime,
        deliveryNote: this.deliveryNote,
        commercialState: 'INICIADA',
        seller: { id: this.seller },
        directContact: { id: this.contact },
        client: { id: this.client }
      };

      if(this.paymentType !== 'CONTADO')
        order.creditTerm = this.paymentType;

      Repository.addOrder(order).then(async (response) => {
        if(response.status < 400) {
          alert('¡Orden fue guardada satisfactoriamente!\nPuede continuar.');
          await Repository.getAllOrders().then((secondResponse) => { if(secondResponse.status < 400) this.setOrders(secondResponse.data); });
          await this.$emit('addedOrder', response.data.id);
          this.overlayProcessing = false;
          await this.close();
        }
      });
    },
    formatText(string, maxLength) {
      return (string.length > maxLength)? `${String(string).substring(0, maxLength - 3)}...` : string;
    },
    closeAddClient() {
      this.client = this.$store.state.clients[0].id;
      this.overlayAddClient = false;
    },
    close() {
      if(this.$store.state.desktop)
        this.$emit('close');
      else
        this.$router.push('/ordenes');
    }
  }
}
</script>

<style scoped>

</style>
