<template>
  <v-card light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Órdenes por Facturar</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <p v-if="$store.state.desktop" class="text-center">Órdenes pendientes por facturar:</p>
      <v-data-table :headers="headers" :items="pendingInvoices">
        <template v-slot:item.clientName="{ item }">
          <p class="my-0">{{ getClientName(item.client) }}</p>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PendingInvoices",
  props: {
    pendingInvoices: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Cliente', value: 'clientName', align: 'left', sortable: true },
        { text: 'Órden', value: 'title', align: 'left', sortable: false },
      ],
    };
  },
  methods: {
    getClientName(client) {
      if(client.clientType === 'PERSONA_JURIDICA')
        return this.$store.state.businesses.find((business) => business.client.id === client.id).businessName;

      let userFound = this.$store.state.contacts.find((contact) => contact.client.id === client.id).user;
      return `${userFound.firstName} ${userFound.lastName}`;
    },
    invoiceOrder() {
      alert('¡Órden fue facturada!');
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
