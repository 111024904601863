<template>
  <v-card :max-width="($store.state.desktop)? '35vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Eliminar Item</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-container fluid>
        <v-row align="center" class="mb-3" justify="center">
          <v-icon class="mr-7 mt-n4" color="secondary">fa-trash</v-icon>
          <p>
            ¿Desea eliminar el item<br>
            <b style="color: #FF1A6C">{{ item.name }}</b>?
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="deleteItem">Eliminar Item</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/item';
import { mapMutations } from 'vuex';
export default {
  name: "DeleteItem",
  props: {
    item: Object,
  },
  methods: {
    ...mapMutations(['setItems']),
    deleteItem() {
      Repository.deleteItem(this.item.id).then((response) => {
        if(response.status < 400) {
          alert('Item fue eliminado satisfactoriamente');
          Repository.getAllItems().then((secondResponse) => {
            if(secondResponse.status < 400)
              this.setItems(secondResponse.data);
          });
          this.close();
        }
      });
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
