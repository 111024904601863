<template>
  <v-card :max-width="($store.state.desktop)? '50vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (!creating)? 'Editando Producto' : (byStandardized)? 'Nuevo Producto Mediante Estandarizado' : 'Nuevo Producto Personalizado' }}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1">Detalles</v-stepper-step>
          <v-stepper-step v-if="byStandardized" step="2">Producto Base</v-stepper-step>
          <v-stepper-step :step="(byStandardized)? 3 : 2">Tipo Impresión</v-stepper-step>
          <v-stepper-step :step="(byStandardized)? 4 : 3">Items Internos</v-stepper-step>
          <v-stepper-step v-if="containsExternalItems" :step="(byStandardized)? 5 : 4">Items Externos</v-stepper-step>
          <v-stepper-step :step="(containsExternalItems && byStandardized)? 6 : (byStandardized || containsExternalItems)? 5 : 4">Confirmación</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="validFirst">
              <p v-if="$store.state.desktop" class="text-center">Detalles del producto {{ (byStandardized)? 'mediante estandarizado' : 'personalizado' }}</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-textarea label="Descripción del Producto" placeholder="Ej. Tarjetas de presentación estándard para empresa"
                                v-model="description" :rules="descriptionRules" counter="150" rows="2" auto-grow outlined/>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
                    <v-text-field label="Ancho en CMS" type="number" v-model="width" :rules="widthRules" outlined/>
                    <p class="mt-5 ml-2">cms</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
                    <v-text-field label="Alto en CMS" type="number" v-model="height" :rules="heightRules" outlined/>
                    <p class="mt-5 ml-2">cms</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="5" xl="5" class="d-flex">
                    <v-text-field label="Unidades" type="number" v-model="units" :rules="unitsRules" outlined/>
                    <p class="mt-5 ml-2">unds</p>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn :disabled="!validFirst" @click="nextStep">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content v-if="byStandardized" step="2">
            <v-form v-model="validStandardizedProduct">
              <p v-if="$store.state.desktop" class="text-center">Selecciona el producto estandarizado de referencia</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-autocomplete :items="$store.state.standardizedProducts" item-value="id" item-text="name"
                                    label="Producto Estandarizado Base" placeholder="Ej. Tarjeta Presentacion" @change="getItemsByStandardized"
                                    v-model="standardizedProduct" :rules="standardizedProductRules" outlined/>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validStandardizedProduct">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="(byStandardized)? 3 : 2">
            <v-form v-model="validSecond">
              <p v-if="$store.state.desktop" class="text-center">Selecciona un tipo de impresión</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-autocomplete :items="printTypes" item-value="id" item-text="name"
                                    label="Tipo de Impresión" placeholder="Ej. Corte Láser"
                                    v-model="printType" :rules="printTypeRules" outlined/>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validSecond">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="(byStandardized)? 4 : 3">
            <v-form v-model="validThird">
              <p v-if="$store.state.desktop" class="text-center">Selecciona los ítems internos del producto</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10"
                         class="d-flex align-center justify-center" v-bind:key="item.id" v-for="item in itemsSelected">
                    <v-autocomplete :items="$store.state.items.filter((partialItem) => partialItem.category !== 'IMPRESION')"
                                    item-value="id" item-text="name"
                                    :label="`Item N° ${item.position}`" placeholder="Ej. Poliestireno 100"
                                    v-model="item.id" :rules="itemRules" @change="verifyNotSameItem(item)" outlined/>
                    <v-icon v-if="verifyDuplicated(item.id)" class="mt-n6 ml-2" color="secondary" small>fa-exclamation-triangle</v-icon>
                    <v-icon v-if="item.position !== 1 && item.position === itemsSelected.length"
                            class="mt-n5 ml-2" @click="deleteLastItem">fa-trash</v-icon>
                  </v-col>
                </v-row>
                <v-row class="mb-5 mt-n3" align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-btn :disabled="verifyCannotAddMore()" width="100%" class="add-item-button" @click="addItem" small>
                      Agregar Item <v-icon class="ml-2" small>fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="my-2"/>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-checkbox :label="(!containsExternalItems)? '¿Requiere de items externos?' : 'Contiene items externos'"
                                v-model="containsExternalItems" dense/>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validThird">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content v-if="containsExternalItems" :step="(byStandardized)? 5 : 4">
            <v-form v-model="validExternalItems">
              <p v-if="$store.state.desktop" class="text-center">Selecciona los ítems externos del producto</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10"
                         class="d-flex align-center justify-center" v-bind:key="item.position" v-for="item in externalItemsSelected">
                    <v-text-field v-model="item.name" style="min-width: 30%" label="Nombre" placeholder="Ej. Andamio"
                                  class="mb-n2" :rules="freeItemNameRules" counter="25" dense outlined/>
                    <v-text-field v-model="item.units" label="Cantidad" type="number"
                                  class="mb-n2 ml-3" :rules="freeItemUnitsRules" dense outlined/>
                    <v-text-field v-model="item.cost" label="Costo (Proveedor)" type="number"
                                  class="mb-n2 mx-3" :rules="freeItemCostRules" dense outlined/>
                    <v-text-field v-model="item.margin" label="Márgen (Porcentual)" type="number"
                                  class="mb-n2" :rules="freeItemMarginRules" dense outlined/>
                    <v-icon v-if="item.position !== 1 && item.position === externalItemsSelected.length"
                            class="mt-n5 ml-2" @click="deleteLastExternalItem">fa-trash</v-icon>
                  </v-col>
                </v-row>
                <v-row class="mb-5" align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-btn width="100%" class="add-item-button" @click="addExternalItem" small>
                      Agregar Item Externo <v-icon class="ml-2" small>fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validExternalItems">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content :step="(containsExternalItems && byStandardized)? 6: (byStandardized || containsExternalItems)? 5 : 4">
            <p v-if="$store.state.desktop" class="text-center">Verifica el producto nuevo:</p>
            <v-container fluid>
              <v-row dense align="center" justify="start">
                <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p class="my-0">Descripción del Producto</p>
                  <h3>{{ formatText(description, 50) }}</h3>

                  <p class="my-0 mt-5">Dimensiones de la unidad del Producto</p>
                  <h3>{{ width }} cms x {{ height }} cms (Área de {{ width * height }} cms²)</h3>

                  <p class="my-0 mt-5">Tipo de Impresión del Producto</p>
                  <h3>{{ printTypeName }}</h3>

                  <p class="my-0 mt-5">Items del Producto</p>
                  <h3 class="mb-5">{{ itemsSelected.length }} Items</h3>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p class="my-0">Unidades del Producto</p>
                  <h3>{{ units }} Unidades</h3>

                  <p class="my-0 mt-5">Valor Unidad</p>
                  <h3>${{ new Intl.NumberFormat().format(Number((totalPrice / units).toFixed(0))) }}</h3>

                  <p style="color: #3700b6" class="my-0 mt-5">Descuento por Volumen</p>
                  <h3 style="color: #3700b6">{{ (discount !== 0)? `$${new Intl.NumberFormat().format(Number(discount.toFixed(0)))}` : 'Sin descuento' }}</h3>

                  <p style="color: #FF1A6C" class="my-0 mt-5">Valor Total</p>
                  <h3 style="color: #FF1A6C" class="mb-5">${{ String(new Intl.NumberFormat().format(Number(totalPrice.toFixed(0)))) }} Total</h3>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                <v-btn v-if="creating" @click="addProduct">
                  Crear Producto <v-icon class="ml-2" small>fa-plus</v-icon>
                </v-btn>
                <v-btn v-else @click="editProduct">
                  Editar Producto <v-icon class="ml-2" small>fa-check</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/product';
import PricingSpecs from '../../Orders/specs/pricingSpecs';
export default {
  name: "SaveOrderProduct",
  props: {
    creating: Boolean,
    byStandardized: Boolean,
    orderId: Number,
    product: Object,
  },
  data() {
    return {
      // Editing
      originalItems: [],

      step: 1,
      description: '',
      width: 0,
      height: 0,
      units: 0,
      printType: 0,
      itemsSelected: [
        { position: 1, id: 0 },
      ],
      standardizedProduct: 0,
      printTypes: [],
      discount: 0,
      totalPrice: 0,

      // External Items
      containsExternalItems: false,
      externalItemsSelected: [
        { position: 1, name: '', cost: 0, margin: 25, units: 0, price: 0 },
      ],

      // Validations
      validFirst: false,
      validStandardizedProduct: false,
      validSecond: false,
      validExternalItems: false,
      validThird: false,
      descriptionRules: [
        n => !!n || 'Descripción del producto es obligatorio',
        n => (n && n.length <= 150) || 'Descripción debe ser menor a 150 caracteres',
      ],
      widthRules: [
        w => !!w || 'Ancho es obligatorio',
        w => (w && Number(w) > 0)|| 'Ancho debe ser mayor a 0 cms',
      ],
      heightRules: [
        h => !!h || 'Alto es obligatorio',
        h => (h && Number(h) > 0)|| 'Alto debe ser mayor a 0 cms',
      ],
      unitsRules: [
        u => !!u || 'Unidades son requeridas',
        u => (u && Number(u) > 0)|| 'Unidades debe ser mayor a 0',
      ],
      printTypeRules: [
        p => !!p || 'Tipo de impresión es obligatoria',
      ],
      standardizedProductRules: [
        p => !!p || 'Product estandarizado base es obligatorio',
      ],
      itemRules: [
        i => !!i || 'Item es requerido',
        i => (i && !this.verifyDuplicated(i)) || 'Item está duplicado',
      ],
      freeItemNameRules: [
        f => !!f || 'Requerido',
        f => (f && f.length <= 25) || 'Hasta 25 letras',
      ],
      freeItemCostRules: [
        f => !!f || 'Requerido',
        f => (f && Number(f) > 0) || 'Mayor a 0',
      ],
      freeItemMarginRules: [
        f => !!f || 'Requerido',
        f => (f && Number(f) >= 25) || 'Mínimo 25%',
      ],
      freeItemUnitsRules: [
        f => !!f || 'Requerido',
        f => (f && Number(f) > 0) || 'Mayor a 0',
      ],
    };
  },
  computed: {
    printTypeName() {
      return (this.printType !== 0)? this.$store.state.items.find((print) => print.id === this.printType).name : '';
    }
  },
  mounted() {
    if(this.orderId === undefined)
      this.close();
    this.setDefaults();
    if(!this.creating)
      this.setDefaultsByEditing();
  },
  methods: {
    setDefaults() {
      this.printTypes = this.$store.state.items.filter((item) => item.category === 'IMPRESION');
      if(this.byStandardized) {
        this.standardizedProduct = this.$store.state.standardizedProducts[0].id;
        this.getItemsByStandardized();
      }
      else
        this.printType = this.printTypes[0].id;
    },
    setDefaultsByEditing() {
      Repository.getItemsFromOrderProduct(this.product.id).then((response) => {
        if(response.status < 400) {
          this.originalItems = response.data;
          this.itemsSelected = [];
          for (let i = 1, realPosition = 1; i - 1 < this.originalItems.length; i++, realPosition++) {
            let item = this.originalItems[i - 1];
            if(item.category === 'IMPRESION') {
              this.printType = item.id;
              realPosition--;
            } else
              this.itemsSelected.push({ position: realPosition, id: item.id  });
          }
        }
      });

      Repository.getAllFreePriceItemsByProduct(this.product.id).then((response) => {
        if(response.status < 400 && response.data.length > 0) {
          this.containsExternalItems = true;
          this.originalExternalItems = response.data;
          this.externalItemsSelected = [];
          for (let i = 1; i - 1 < this.originalExternalItems.length; i++) {
            let item = this.originalExternalItems[i - 1];
            let cost = (item.price * (1 - (item.margin / 100))) / item.units;
            this.externalItemsSelected.push({ position: i, name: item.name, cost: cost, margin: item.margin, units: item.units, price: item.price  });
          }
        }
      });

      this.description = this.product.description;
      this.width = this.product.width;
      this.height = this.product.height;
      this.units = this.product.units;
    },
    getItemsByStandardized() {
      Repository.getItemsFromStandardizedProduct(this.standardizedProduct).then((response) => {
        if(response.status < 400) {
          this.itemsSelected = [];
          let itemsFromStandardized = response.data;
          for (let i = 1, realPosition = 1; i - 1 < itemsFromStandardized.length; i++, realPosition++) {
            let item = itemsFromStandardized[i - 1];
            if(item.category === 'IMPRESION') {
              this.printType = item.id;
              realPosition--;
            } else
              this.itemsSelected.push({ position: realPosition, id: item.id  });
          }
        }
      });
    },
    processPricing() {
      this.totalPrice = 0;
      let totalArea = this.width * this.height * this.units;
      let totalDiameter = 2 * (Number(this.width) + Number(this.height)) * this.units;

      let itemProperties = [];
      itemProperties.push(this.$store.state.items.find((item) => item.id === this.printType));
      this.itemsSelected.forEach((itemSelected) => { itemProperties.push(this.$store.state.items.find((item) => item.id === itemSelected.id)); });

      itemProperties.forEach((item) => {
        if(item.category === 'IMPRESION')
          this.totalPrice += this.getPrintTypeCost(item, totalArea);
        else
          this.totalPrice += ((item.measureScale === 'CMS_CUADRADOS')? totalArea : (item.measureScale === 'CMS_LINEALES')? totalDiameter : this.units) * item.unitValue;
      });

      // Sums the external items
      if(this.containsExternalItems)
        for(let index = 0; index < this.externalItemsSelected.length; index++) {
          let itemPrice = (this.externalItemsSelected[index].cost / (1 - (this.externalItemsSelected[index].margin / 100))) * this.externalItemsSelected[index].units;
          this.externalItemsSelected[index].price = itemPrice;
          this.totalPrice += Number(itemPrice);
        }
    },
    getPrintTypeCost(item, totalArea) {
      this.discount = 0;
      let specsFound = PricingSpecs.find((partialItem) => partialItem.itemId === item.id);
      let normalPrice = totalArea * item.unitValue;
      if(specsFound === undefined)
        return normalPrice;

      // If is above minimums
      if(totalArea < specsFound.minSquareCms)
        return specsFound.minSquareCms * item.unitValue;

      // If does not count with discount
      if(totalArea < specsFound.discountScales[0].fromCms)
        return normalPrice;

      //If counts with discount
      for(let i = 1; i < specsFound.discountScales.length; i++)
        if(totalArea < specsFound.discountScales[i].fromCms) {
          let discountedPricing = normalPrice * specsFound.discountScales[i - 1].rate;
          this.discount = normalPrice - discountedPricing;
          return discountedPricing;
        }
        else if(i === specsFound.discountScales.length - 1) {
          let discountedPricing = normalPrice * specsFound.discountScales[i].rate;
          this.discount = normalPrice - discountedPricing;
          return discountedPricing;
        }
    },
    backStep() {
      this.step--;
    },
    nextStep() {
      if((this.containsExternalItems && this.byStandardized && this.step === 5) || ((this.byStandardized || this.containsExternalItems) && this.step === 4) || (!this.byStandardized && this.step === 3))
        this.processPricing();
      this.step++;
    },
    addItem() {
      this.itemsSelected.push({ position: (this.itemsSelected.length + 1), id: 0 });
    },
    addExternalItem() {
      this.externalItemsSelected.push({ position: (this.externalItemsSelected.length + 1), name: '', cost: 0, margin: 25, price: 0 });
    },
    verifyDuplicated(itemEvaluatedId) {
      return this.itemsSelected.filter((item) => item.id === itemEvaluatedId).length > 1;
    },
    verifyNotSameItem(itemChanged) {
      if(this.verifyDuplicated(itemChanged.id)) {
        alert('No puede escoger items repetidos.\nPor favor intente de nuevo.');
        if(itemChanged.position === this.itemsSelected.length)
          this.itemsSelected.pop();
      }
    },
    verifyCannotAddMore(){
      return this.itemsSelected[this.itemsSelected.length - 1].id === 0;
    },
    deleteLastItem() {
      this.itemsSelected.pop();
    },
    deleteLastExternalItem() {
      this.externalItemsSelected.pop();
    },
    addProduct() {
      let unitArea = this.width * this.height;
      let product = {
        description: this.description,
        thirdParty: false,
        units: this.units,
        width: this.width,
        height: this.height,
        unitArea: unitArea,
        totalArea: (unitArea) * this.units,
        totalPrice: this.totalPrice,
        discount: this.discount,
        order: { id: this.orderId },
      };

      let itemsId = [this.printType];
      this.itemsSelected.forEach((item) => itemsId.push(item.id));

      Repository.addOrderProduct(product).then((response) => {
        if (response.status < 400) {

          if(this.containsExternalItems) {
            this.externalItemsSelected.forEach((externalItem) => {
              let freePriceItem = {
                product: { id: response.data.id },
                name: externalItem.name,
                price: externalItem.price,
                margin: externalItem.margin,
                units: externalItem.units,
              }

              Repository.addFreePriceItem(freePriceItem).then((thirdResponse) => {
                if(thirdResponse.status > 400) alert('Ocurrió un error creando el item de precio libre.\nIntenta luego.');
              });
            });
          }

          let itemsData = {
            productId: response.data.id,
            items: itemsId
          }

          Repository.assignItemsToOrderProduct(itemsData).then((secondResponse) => {
            if(secondResponse.status < 400) {
              alert(`¡${this.description} fue ${(this.creating)? 'creado': 'editado'}!`);
              this.productSaved();
              this.close();
            }
          });
        }
      });
    },
    editProduct() {
      Repository.deleteOrderProduct(this.product.id).then((response) => {
        if(response.status < 400)
          this.addProduct();
      });
    },
    formatText(string, maxLength) {
      return (string.length > maxLength)? `${String(string).substring(0, maxLength - 3)}...` : string;
    },
    productSaved() {
      this.$emit((this.creating)? 'productAdded' : 'productEdited');
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.add-item-button {
  background-color: #3700b6 !important;
  color: #ffffff !important;
}
</style>
