<template>
  <v-container class="mt-5" fluid>
    <h1 class="text-center">Items</h1>
    <v-row align="center" class="my-5" justify="center">
      <v-col cols="12" lg="9" md="9" sm="12" xl="9">
        <ItemsLists @addItem="openAddItem" @editItem="openEditItem" @deleteItem="openDeleteItem"/>
      </v-col>
    </v-row>

    <!-- Overlays !-->
    <v-overlay v-model="overlayAddItem">
      <SaveItem :creating="true" @close="closeAddItem"/>
    </v-overlay>
    <v-overlay v-model="overlayEditItem">
      <SaveItem :creating="false" :item="selectedItem" @close="closeEditItem"/>
    </v-overlay>
    <v-overlay v-model="overlayDeleteItem">
      <DeleteItem :item="selectedItem" @close="closeDeleteItem"/>
    </v-overlay>
  </v-container>
</template>

<script>
import Repository from '../../repositories/item';
import { mapMutations } from 'vuex';
import ItemsLists from "@/views/Items/components/ItemsLists";
import DeleteItem from "@/views/Items/components/DeleteItem";
import SaveItem from "@/views/Items/components/SaveItem";

export default {
  name: "ItemsIndex",
  components: {SaveItem, DeleteItem, ItemsLists},
  data() {
    return {
      selectedItem: null,

      overlayAddItem: false,
      overlayEditItem: false,
      overlayDeleteItem: false,
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapMutations(['setItems']),
    updateData() {
      Repository.getAllItems().then((response) => {
        if(response.status < 400)
          this.setItems(response.data);
      });
    },
    openAddItem() {
      if(this.$store.state.desktop)
        this.overlayAddItem = true;
      else
        this.$router.push('/agregar-item');
    },
    openEditItem(item) {
      this.selectedItem = item;
      this.overlayEditItem = true;
    },
    openDeleteItem(item) {
      this.selectedItem = item;
      this.overlayDeleteItem = true;
    },
    closeAddItem() {
      this.overlayAddItem = false;
    },
    closeEditItem() {
      this.overlayEditItem = false;
    },
    closeDeleteItem() {
      this.overlayDeleteItem = false;
    }
  }
}
</script>

<style scoped>

</style>
