<template>
  <v-card :max-width="($store.state.desktop)? '35vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Eliminar Producto</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-container fluid>
        <v-row class="mb-3" align="center" justify="center">
          <v-icon color="secondary" class="mr-7 mt-n4">fa-trash</v-icon>
          <p>
            ¿Desea eliminar el producto<br>
            <b style="color: #FF1A6C">{{ (isStandardized)? product.name : product.description }}</b>?
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="deleteProduct">Eliminar Producto</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/product';
import { mapMutations } from 'vuex';

export default {
  name: "DeleteProduct",
  props: {
    product: Object,
    isStandardized: Boolean,
  },
  methods: {
    ...mapMutations(['setStandardizedProducts']),
    deleteProduct() {
      let promise = (this.isStandardized)? Repository.deleteStandardizedProduct(this.product.id) : Repository.deleteOrderProduct(this.product.id);
      promise.then((response) => {
        if(response.status < 400) {
          alert('¡El producto fue eliminado satisfactoriamente!');
          if(this.isStandardized)
            Repository.getAllStandardizedProducts().then((response) => { if(response.status < 400) this.setStandardizedProducts(response.data); });
          else
            this.productDeleted();
          this.close();
        }
      });
    },
    productDeleted() {
      this.$emit('productDeleted');
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
