<template>
  <v-card id="pdfTarget" :max-width="($store.state.desktop)? '60vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ formatText(`Órden ID: #${order.id}`, 45) }}</h2>
      <v-chip color="primary" small class="ml-3">
        <p class="my-0" style="font-size: 12px">{{ order.commercialState.replaceAll('_', ' ') }}</p>
      </v-chip>
      <v-chip v-if="order.operationalState !== null" color="secondary" class="ml-1" small >
        <p class="my-0" style="font-size: 12px">{{ order.operationalState.replaceAll('_', ' ') }}</p>
      </v-chip>
      <v-chip v-if="order.accountableState !== null" color="accent" text-color="black" class="ml-1" small>
        <p class="my-0" style="font-size: 12px">{{ order.accountableState.replaceAll('_', ' ') }}</p>
      </v-chip>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="overflow-y-auto pa-5" style="max-height: 70vh !important;">
      <v-form v-if="step === 1" v-model="firstValid">
        <v-container fluid>
          <v-row v-if="orderProducts.length > 0" class="mb-5" align="center" justify="center" dense>
            <v-col style="border: 1px #FF1A6C solid; border-radius: 20px;" cols="12" sm="12" md="12" lg="5" xl="5">
              <h2 class="text-center" style="color: #FF1A6C">${{ new Intl.NumberFormat().format(Number(getFullTotalPrice.toFixed(0))) }}</h2>
              <p class="text-center my-0">Precio Total de la Órden</p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10" :class="(isEditable)? 'd-flex align-center justify-start mt-3 mb-n5' : ''">
              <h3 v-if="!isEditable" style="color: #3700b6">{{ order.title }}</h3>
              <v-text-field v-else v-model="title" label="Título de la órden" counter="50" outlined/>
              <v-icon v-if="previousTitle !== title" small class="icon ml-3 mt-n5" color="primary" @click="editTitle">fa-check</v-icon>
            </v-col>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10">
              <p class="my-0 mt-3">Cliente</p>
              <h4>{{ formatText(getClientName(), 50) }}</h4>
            </v-col>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10">
              <p class="my-0 mt-3">Vendedor</p>
              <h4>{{ formatText(getSellerName(), 50) }}</h4>
            </v-col>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10" :class="(isEditable)? 'd-flex align-center justify-start mt-3 mb-n3' : ''">
              <p v-if="!isEditable" class="my-0 mt-3">Forma de Pago</p>
              <h4 v-if="!isEditable">{{ (order.creditTerm !== null)? order.creditTerm : 'CONTADO' }}</h4>
              <v-select v-else :disabled="paymentTypes.length === 1" :items="paymentTypes" v-model="creditTerm" label="Forma de Pago" :rules="rules" outlined/>
              <v-icon v-if="previousCreditTerm !== creditTerm" small class="icon ml-3 mt-n5" color="primary" @click="editCreditTerm">fa-check</v-icon>
            </v-col>
            <v-col cols="12" lg="10" md="10" sm="12" xl="10" :class="(isEditable)? 'd-flex align-center justify-start mb-n5' : ''">
              <p v-if="!isEditable" class="my-0 mt-3">Tiempo de Entrega</p>
              <h4 v-if="!isEditable">{{ order.workTime }}</h4>
              <v-select v-else :items="$store.state.constants.deliveryTimes" v-model="workTime" label="Tiempo de Entrega" :rules="rules" outlined/>
              <v-icon v-if="previousWorkTime !== workTime" small class="icon ml-3 mt-n5" color="primary" @click="editWorkTime">fa-check</v-icon>
            </v-col>
            <v-col v-if="order.deliveryNote !== '' || isEditable" cols="12" lg="10" md="10" sm="12" xl="10" :class="(isEditable)? 'd-flex align-center justify-start mb-n3' : ''">
              <h4 v-if="!isEditable" style="color: #FF1A6C" class="mt-2">Consideración Entrega</h4>
              <p v-if="!isEditable" class="my-0" >{{ order.deliveryNote }}</p>
              <v-textarea v-else v-model="deliveryNote" label="Consideraciones de la Entrega (Opcional)" class="mt-3"
                          placeholder="Ej. Enviar a Cl 5 #20-20" rows="1" auto-grow counter="200" outlined/>
              <v-icon v-if="previousDeliveryNote !== deliveryNote" small class="icon ml-3 mt-n3" color="primary" @click="editDeliveryNote">fa-check</v-icon>
            </v-col>
          </v-row>
          <v-row class="mt-10" align="center" justify="center">
            <v-btn @click="goToStates">
              Cambiar Estado <v-icon class="ml-2" small>fa-project-diagram</v-icon>
            </v-btn>
            <v-btn class="mx-3" @click="goToProducts">
              {{ (order.commercialState !== 'INICIADA')? 'Ver Cotización' : (orderProducts.length > 0)? 'Seguir Cotización' : 'Crear Cotización' }}
              <v-icon class="ml-2" small>fa-receipt</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
      <v-form v-else-if="step === 2">
        <v-container fluid>
          <v-row>
            <v-col>
              <h3>Estado Comercial</h3>
              <div class="d-flex justify-space-between mt-2">
                <v-chip v-bind:key="state.step" v-for="state in commercialStates"
                        :color="(commercialStateIndex(state.state) === 0)? 'primary' : ''"
                        :disabled="commercialStateIndex(state.state) === 1" @click="changeCommercialState(state.state)">
                  <p style="font-size: 13px;" class="my-0">{{ state.state.replaceAll('_', ' ') }}</p>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Estado Operacional</h3>
              <p v-if="order.commercialState !== 'APROBADA'">Aún no se ha aprobado comercialmente la órden</p>
              <div v-else class="d-flex justify-space-between mt-2">
                <v-chip v-bind:key="state.step" v-for="state in operationalStates"
                        :color="(operationalStateIndex(state.state) === 0)? 'secondary' : ''"
                        :disabled="operationalStateIndex(state.state) === 1" @click="changeOperationalState(state.state)">
                  <p style="font-size: 13px;" class="my-0">{{ state.state.replaceAll('_', ' ') }}</p>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Estado Contable</h3>
              <p v-if="order.commercialState !== 'APROBADA'">Aún no se ha aprobado comercialmente la órden</p>
              <div v-else class="d-flex justify-space-between mt-2">
                <v-chip v-bind:key="state.step" v-for="state in accountableStates"
                        :text-color="(accountableStateIndex(state.state) === 0)? 'black': ''"
                        :color="(accountableStateIndex(state.state) === 0)? 'accent' : ''"
                        :disabled="accountableStateIndex(state.state) === 1" @click="changeAccountableState(state.state)">
                  <p style="font-size: 13px;" class="my-0">{{ state.state.replaceAll('_', ' ') }}</p>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-5" align="center" justify="center">
            <v-btn @click="goToDetails">« Ir a Detalles</v-btn>
          </v-row>
        </v-container>
      </v-form>
      <v-form v-else-if="step === 3">
        <v-container fluid>
          <v-row v-if="orderProducts.length > 0" class="mb-7" align="center" justify="center" dense>
            <v-col style="border: 1px #FF1A6C solid; border-radius: 20px;" cols="12" sm="12" md="12" lg="7" xl="7">
              <h2 class="text-center" style="color: #FF1A6C">${{ new Intl.NumberFormat().format(Number(getFullTotalPrice.toFixed(0))) }}</h2>
              <p class="text-center my-0">Precio Total de la Órden</p>
            </v-col>
          </v-row>
          <p v-if="$store.state.desktop && isEditable" class="text-center">Agrega productos a la órden</p>
          <v-row dense align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="d-flex" :style="(isLastProduct(product.id))? '' : 'border-bottom: 1px solid #e0e0e0'"
                   v-bind:key="product.id" v-for="product in orderProducts">
              <div>
                <h4 class="text-start">
                  {{ formatText(product.description, 45) }}
                  <v-chip v-if="product.thirdParty" class="ml-1 mt-n1" color="primary" x-small outlined>
                    <p style="font-size: 13px;" class="my-0">Tercerizado <v-icon x-small>fa-share</v-icon></p>
                  </v-chip>
                </h4>
                <p>
                  Ancho: {{ product.width }} cms, Alto: {{ product.height }} cms <br>
                  Unidades: {{ product.units }}, Valor Unidad: ${{ new Intl.NumberFormat().format(Number((product.totalPrice / product.units).toFixed(0))) }}<br>
                  <font color="#FF1A6C">Valor Total: ${{ new Intl.NumberFormat().format(Number(product.totalPrice.toFixed(0))) }}</font>
                </p>
              </div>
              <v-spacer/>
              <v-icon v-if="isEditable" class="mt-n1" @click="seeProductItems(product)" small>fa-cube</v-icon>
              <v-icon v-if="isEditable" class="mt-n1 mx-3" @click="editProduct(product)" small>fa-edit</v-icon>
              <v-icon v-if="isEditable" class="mt-n1 mr-3" @click="duplicateProduct(product)" small>fa-copy</v-icon>
              <v-icon v-if="isEditable" class="mt-n1" @click="deleteProduct(product)" small>fa-trash</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="isEditable" class="my-2" align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-btn width="100%" class="add-item-button" @click="addProduct" color="primary" :small="orderProducts.length > 0">
                {{ (orderProducts.length > 0) ? 'Agregar Producto' : 'Agregar Primer Producto' }}
                <v-icon class="ml-2" small>fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="orderProducts.length > 0" class="mt-n3" align="center" justify="start">
            <v-col v-if="isEditable" class="d-flex align-center justify-start">
              <v-checkbox v-model="hasDelivery" :label="(!hasDelivery)? '¿La órden cuenta con domicilio?' : 'Órden con domicilio de valor:' "/>
              <v-text-field v-if="hasDelivery" v-model="deliveryFee" type="number" :rules="deliveryCostRules" class="ml-5 mb-n4" dense outlined/>
              <v-icon v-if="previousHasDelivery !== hasDelivery || previousDeliveryFee !== deliveryFee"
                      :disabled="(deliveryFee === null || deliveryFee < 5000)" small class="icon ml-3" color="primary" @click="editDeliveryFee">fa-check</v-icon>
            </v-col>
            <v-col v-else-if="hasDelivery">
              <p style="color: #3700b6">Domicilio por valor de <b>{{ new Intl.NumberFormat().format(order.deliveryFee) }}</b></p>
            </v-col>
          </v-row>
          <v-row v-if="orderProducts.length > 0 && !hasDiscount" :class="(isEditable)? 'mt-n10' : 'mt-0'" align="center" justify="start">
            <v-col v-if="isEditable" class="d-flex align-center justify-start">
              <v-checkbox v-model="hasBonus" :label="(!hasBonus)? '¿La órden cuenta con prima de servicio?' : 'Órden con prima de servicio porcentual:'" @change="changedHasBonus"/>
              <v-select v-if="hasBonus" :items="serviceBonuses" v-model="bonusPercentage"
                        item-value="value" item-text="text" type="number" class="ml-5 mb-n4" dense outlined/>
              <v-icon v-if="previousHasBonus !== hasBonus || previousBonusPercentage !== bonusPercentage" small class="icon ml-3" color="primary" @click="editBonus">fa-check</v-icon>
            </v-col>
            <v-col v-else-if="hasBonus">
              <p style="color: #3700b6">Prima de servicio por valor de <b>{{ new Intl.NumberFormat().format(order.bonusPercentage) }}%</b></p>
            </v-col>
          </v-row>
          <v-row v-if="orderProducts.length > 0 && !hasBonus" :class="(isEditable)? 'mt-n10' : 'mt-0'" align="center" justify="start">
            <v-col v-if="isEditable" class="d-flex align-center justify-start">
              <v-checkbox v-model="hasDiscount" :label="(!hasDiscount)? '¿La órden cuenta con descuento?' : 'Órden con descuento porcentual:'" @change="changedHasDiscount"/>
              <v-select v-if="hasDiscount" :items="serviceDiscounts" v-model="discountPercentage"
                        item-value="value" item-text="text" type="number" class="ml-5 mb-n4" dense outlined/>
              <v-icon v-if="previousHasDiscount !== hasDiscount || previousDiscountPercentage !== discountPercentage" small class="icon ml-3" color="primary" @click="editDiscount">fa-check</v-icon>
            </v-col>
            <v-col v-else-if="hasDiscount">
              <p style="color: #3700b6">Descuento por valor de <b>{{ new Intl.NumberFormat().format(order.discountPercentage) }}%</b></p>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn @click="goToDetails">« Ir a Detalles</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <!-- Overlay product type selection !-->
    <v-overlay v-model="overlayProductType">
      <ProductTypeSelection @typeChoose="productTypeChoose" @close="overlayProductType = false"/>
    </v-overlay>

    <!-- Overlay new custom product !-->
    <v-overlay v-model="overlayNewCustomProduct">
      <SaveOrderProduct :order-id="order.id" :creating="true" :by-standardized="false" @productAdded="productUpdated" @close="overlayNewCustomProduct = false"/>
    </v-overlay>

    <!-- Overlay new product by standardized !-->
    <v-overlay v-model="overlayStandardizedProduct">
      <SaveOrderProduct :order-id="order.id" :creating="true" :by-standardized="true" @productAdded="productUpdated" @close="overlayStandardizedProduct = false"/>
    </v-overlay>

    <!-- Overlay new third party product !-->
    <v-overlay v-model="overlayThirdPartyProduct">
      <SaveThirdPartyProduct :creating="true" :order-id="order.id" @productAdded="productUpdated" @close="overlayThirdPartyProduct = false"/>
    </v-overlay>

    <!-- Overlay edit product !-->
    <v-overlay v-model="overlayEditProduct">
      <SaveOrderProduct :order-id="order.id" :product="selectedProduct" :creating="false" :by-standardized="false" @productEdited="productUpdated" @close="overlayEditProduct = false"/>
    </v-overlay>

    <!-- Overlay duplicate product !-->
    <v-overlay v-model="overlayDuplicateProduct">
      <DuplicateProduct :product="selectedProduct" @productDuplicated="productUpdated" @close="overlayDuplicateProduct = false"/>
    </v-overlay>

    <!-- Overlay edit third party product !-->
    <v-overlay v-model="overlayEditThirdPartyProduct">
      <SaveThirdPartyProduct :creating="false" :product="selectedProduct" :order-id="order.id" @productAdded="productUpdated" @close="overlayEditThirdPartyProduct = false"/>
    </v-overlay>

    <!-- Overlay change order status !-->
    <v-overlay v-model="overlayChangeStatus">
      <ChangeState :order="order" :status-type="statusTypeToChange" :new-status="newStatus" @close="overlayChangeStatus = false"/>
    </v-overlay>

    <!-- Overlay delete product !-->
    <v-overlay v-model="overlayDeleteProduct">
      <DeleteProduct :is-standardized="false" :product="selectedProduct" @productDeleted="productUpdated" @close="overlayDeleteProduct = false"/>
    </v-overlay>

    <!-- Overlay see product items !-->
    <v-overlay v-model="overlayProductItems">
      <ProductItems :product="selectedProduct" :is-standardized="false" @close="overlayProductItems = false" />
    </v-overlay>
  </v-card>
</template>

<script>
import OrderRepository from '../../../repositories/order';
import ProductRepository from '../../../repositories/product';
import ProductTypeSelection from "@/views/Products/components/ProductTypeSelection";
import SaveOrderProduct from "@/views/Products/components/SaveOrderProduct";
import SaveThirdPartyProduct from "@/views/Products/components/SaveThirdPartyProduct";
import ChangeState from "@/views/Orders/components/ChangeState";
import DeleteProduct from "@/views/Products/components/DeleteProduct";
import ProductItems from "@/views/Products/components/ProductItems";
import DuplicateProduct from "@/views/Products/components/DuplicateProduct";

export default {
  name: "EditOrder",
  components: {
    DuplicateProduct,
    ProductItems, DeleteProduct, ChangeState, SaveThirdPartyProduct, SaveOrderProduct, ProductTypeSelection},
  props: {
    order: Object
  },
  data() {
    return {
      isEditable: true,
      step: 1,

      // Title
      title: '',
      previousTitle: '',

      // Delivery Fee
      hasDelivery: false,
      deliveryFee: 0,
      previousDeliveryFee: 0,
      previousHasDelivery: false,

      // Delivery Note
      deliveryNote: '',
      previousDeliveryNote: '',

      // Work Time
      workTime: '',
      previousWorkTime: '',

      // CreditTerm
      paymentTypes: [],
      creditTerm: '',
      previousCreditTerm: '',

      // Bonus
      hasBonus: false,
      bonusPercentage: 5,
      previousHasBonus: false,
      previousBonusPercentage: 0,
      serviceBonuses: [
        { text: '+5%', value: 5 },
        { text: '+10%', value: 10 },
        { text: '+15%', value: 15 },
        { text: '+20%', value: 20 },
        { text: '+25%', value: 25 },
        { text: '+30%', value: 30 },
      ],

      // Discount
      hasDiscount: false,
      discountPercentage: 5,
      previousHasDiscount: false,
      previousDiscountPercentage: 0,
      serviceDiscounts: [
        { text: '-5%', value: 5 },
        { text: '-10%', value: 10 },
        { text: '-15%', value: 15 },
        { text: '-20%', value: 20 },
      ],

      // States
      commercialStates: [
        { step: 1, state: 'INICIADA' },
        { step: 2, state: 'PRESENTADA' },
        { step: 3, state: 'APROBADA' },
        { step: 4, state: 'RECHAZADA' },
      ],
      operationalStates: [
        { step: 1, state: 'REVISION_ARCHIVOS' },
        { step: 2, state: 'LIBERADA' },
        { step: 3, state: 'EN_DESPACHO' },
        { step: 4, state: 'ENTREGADA' },
      ],
      accountableStates: [
        { step: 1, state: 'ABONADA' },
        { step: 2, state: 'PENDIENTE_FACTURAR' },
        { step: 3, state: 'REMISIONADA' },
        { step: 4, state: 'FACTURADA' },
      ],

      newStatus: '',
      statusTypeToChange: 'COMMERCIAL',
      overlayChangeStatus: false,

      // Products
      selectedProduct: null,
      overlayProductType: false,
      overlayNewCustomProduct: false,
      overlayStandardizedProduct: false,
      overlayThirdPartyProduct: false,
      overlayEditThirdPartyProduct: false,
      overlayEditProduct: false,
      overlayDuplicateProduct: false,
      overlayDeleteProduct: false,
      overlayProductItems: false,
      orderProducts: [],

      // Validation
      firstValid: false,
      titleRules: [
        t => !!t || 'Título Es obligatorio',
        t => (t && t.length <= 50) || 'Debe ser menor a 50 caracteres',
      ],
      rules: [
        r => !!r || 'Es obligatorio',
      ],
      deliveryCostRules: [
        d => !!d || 'Es obligatorio',
        d => (d && Number(d) >= 5000) || 'Valor mínimo de 5.000',
      ],
      numberRules: [
        n => !!n || 'Es obligatorio',
        n => (n && Number(n) > 0) || 'Debe ser mayor a 0',
      ],
    };
  },
  computed: {
    getFullTotalPrice() {
      let total = this.order.totalPrice;
      if(this.order.bonusPercentage !== null) total += Number(this.order.bonusFee);
      if(this.order.discountPercentage !== null) total -= Number(this.order.discountFee);
      total += Number(this.order.deliveryFee);
      return Number(total);
    },
  },
  mounted() {
    this.setDefaults();
    this.getOrderProducts();
  },
  methods: {
    setDefaults() {
      this.updatePaymentType();
      this.isEditable = (this.order.commercialState === 'INICIADA');
      this.title = this.order.title;
      this.previousTitle = this.title;
      this.hasDelivery = this.order.deliveryFee !== null;

      if(this.hasDelivery) {
        this.previousHasDelivery = true;
        this.deliveryFee = this.order.deliveryFee;
        this.previousDeliveryFee = this.deliveryFee;
      }
      if(this.order.deliveryNote !== null) {
        this.deliveryNote = this.order.deliveryNote;
        this.previousDeliveryNote = this.deliveryNote;
      }
      this.workTime = this.order.workTime;
      this.previousWorkTime = this.workTime;

      this.creditTerm = (this.order.creditTerm == null)? 'CONTADO' : this.order.creditTerm;
      this.previousCreditTerm = this.creditTerm;

      this.hasBonus = this.order.bonusPercentage !== null;
      if(this.hasBonus) {
        this.previousHasBonus = true;
        this.bonusPercentage = this.order.bonusPercentage;
        this.previousBonusPercentage = this.bonusPercentage;
      }
      this.hasDiscount = this.order.discountPercentage !== null;
      if(this.hasDiscount) {
        this.previousHasDiscount = true;
        this.discountPercentage = this.order.discountPercentage;
        this.previousDiscountPercentage = this.discountPercentage;
      }
      this.changedHasBonus();
      this.changedHasDiscount();
    },
    getOrderProducts() {
      ProductRepository.getAllOrderProductsFromOrder(this.order.id).then((response) => {{
        if(response.status < 400)
          this.orderProducts = response.data;
      }});
    },
    changedHasBonus() {
      if(!this.hasBonus)
        this.bonusPercentage = 0;
      else if(this.hasBonus && this.order.bonusPercentage !== null)
        this.bonusPercentage = this.order.bonusPercentage;
      else
        this.bonusPercentage = 5;
    },
    changedHasDiscount() {
      if(!this.hasDiscount)
        this.discountPercentage = 0;
      else if(this.hasBonus && this.order.discountPercentage !== null)
        this.discountPercentage = this.order.discountPercentage;
      else
        this.discountPercentage = 5;
    },
    updatePricing() {
      OrderRepository.processPricing(this.order.id).then((response) => {
        if(response.status < 400) {
          this.order.totalPrice = response.data;
        }
      });
    },
    updatePaymentType() {
      this.paymentTypes = ['CONTADO'];
      let clientFound = this.$store.state.clients.find((client) => client.id === this.order.client.id);
      console.log(clientFound)
      if(clientFound !== undefined && clientFound.creditTerm !== null && clientFound.approvedCredit)
        this.paymentTypes.push(clientFound.creditTerm);
      this.creditTerm = this.paymentTypes[0];
    },
    editTitle() {
      let editData = {
        orderId: this.order.id,
        title: this.title,
      };

      OrderRepository.editTitle(editData).then((response) => {
        if(response.status < 400) {
          this.order.title = this.title;
          this.previousTitle = this.title;
          alert('¡Título fue modificado satisfactoriamente!');
        }
      });
    },
    editDeliveryFee() {
      let editData = {
        orderId: this.order.id,
        deliveryFee: (this.hasDelivery)? this.deliveryFee : 0,
      };

      OrderRepository.editDeliveryFee(editData).then((response) => {
        if(response.status < 400) {
          this.order.deliveryFee = (this.hasDelivery)? this.deliveryFee : 0;
          this.previousDeliveryFee = this.deliveryFee;
          this.previousHasDelivery = this.hasDelivery;
          alert('¡Valor del Domicilio fue modificado satisfactoriamente!');
        }
      });
    },
    editDeliveryNote() {
      let editData = {
        orderId: this.order.id,
        deliveryNote: this.deliveryNote,
      };

      OrderRepository.editDeliveryNote(editData).then((response) => {
        if(response.status < 400) {
          this.order.deliveryNote = this.deliveryNote;
          this.previousDeliveryNote = this.deliveryNote;
          alert('¡Consideración de Entrega fue modificada satisfactoriamente!');
        }
      });
    },
    editWorkTime() {
      let editData = {
        orderId: this.order.id,
        workTime: this.workTime,
      };

      OrderRepository.editWorkTime(editData).then((response) => {
        if(response.status < 400) {
          this.order.workTime = this.workTime;
          this.previousWorkTime = this.workTime;
          alert('¡Tiempo de Entrega fue modificado satisfactoriamente!');
        }
      });
    },
    editCreditTerm() {
      let editData = {
        orderId: this.order.id,
        creditTerm: (this.creditTerm === 'CONTADO')? null : this.creditTerm,
      };

      OrderRepository.editCreditTerm(editData).then((response) => {
        if(response.status < 400) {
          this.order.creditTerm = (this.creditTerm === 'CONTADO')? null : this.creditTerm;
          this.previousCreditTerm = this.creditTerm;
          alert('¡Forma de Pago fue modificada satisfactoriamente!');
        }
      });
    },
    editBonus() {
      let editData = {
        orderId: this.order.id,
        bonusPercentage: (this.hasBonus)? this.bonusPercentage : 0,
      };

      OrderRepository.editBonus(editData).then((response) => {
        if(response.status < 400) {
          this.order.bonusPercentage = this.bonusPercentage;
          this.order.bonusFee = this.order.totalPrice * (this.bonusPercentage / 100);
          this.previousBonusPercentage = this.bonusPercentage;
          this.previousHasBonus = this.hasBonus;
          alert('¡Prima de Servicio fue modificada satisfactoriamente!');

          // Resets discount
          this.hasDiscount = false;
          this.discountPercentage = 0;
          this.previousHasDiscount = false;
          this.order.discountPercentage = null;
          this.order.discountFee = null;
        }
      });
    },
    editDiscount() {
      let editData = {
        orderId: this.order.id,
        discountPercentage: (this.hasDiscount)? this.discountPercentage : 0,
      };

      OrderRepository.editDiscount(editData).then((response) => {
        if(response.status < 400) {
          this.order.discountPercentage = this.discountPercentage;
          this.order.discountFee = this.order.totalPrice * (this.discountPercentage / 100);
          this.previousDiscountPercentage = this.discountPercentage;
          this.previousHasDiscount = this.hasDiscount;
          alert('¡Descuento fue modificado satisfactoriamente!');


          // Resets bonus
          this.hasBonus = false;
          this.bonusPercentage = 0;
          this.previousHasBonus = false;
          this.order.bonusPercentage = null;
          this.order.bonusFee = null;
        }
      });
    },
    goToDetails() {
      this.step = 1;
    },
    goToStates() {
      this.step = 2;
    },
    goToProducts() {
      this.step = 3;
    },
    getClientName() {
      let clientId = this.order.client.id;
      if(this.order.client.clientType === 'PERSONA_JURIDICA')
          return this.$store.state.businesses.find((business) => business.client.id === clientId).businessName;
      let contactFound = this.$store.state.contacts.find((contact) => contact.client.id === clientId);
      return `${contactFound.user.firstName} ${contactFound.user.lastName}`;
    },
    getSellerName() {
      return `${this.order.seller.user.firstName} ${this.order.seller.user.lastName}`;
    },
    commercialStateIndex(partialState) {
      if(this.order.commercialState === null) return -1; // If does not assigned
      let current = this.commercialStates.find((state) => state.state === this.order.commercialState).step;
      let partial = this.commercialStates.find((state) => state.state === partialState).step;
      return (current === partial)? 0 : (current < partial)? -1 : 1;
    },
    operationalStateIndex(partialState) {
      if(this.order.operationalState === null) return -1; // If does not assigned
      let current = this.operationalStates.find((state) => state.state === this.order.operationalState).step;
      let partial = this.operationalStates.find((state) => state.state === partialState).step;
      return (current === partial)? 0 : (current < partial)? -1 : 1;
    },
    accountableStateIndex(partialState) {
      if(this.order.accountableState === null) return -1; // If does not assigned
      let current = this.accountableStates.find((state) => state.state === this.order.accountableState).step;
      let partial = this.accountableStates.find((state) => state.state === partialState).step;
      return (current === partial)? 0 : (current < partial)? -1 : 1;
    },
    changeCommercialState(newStatus) {
      if(this.order.commercialState !== newStatus) {
        this.newStatus = newStatus;
        this.statusTypeToChange = 'COMMERCIAL';
        if((this.newStatus === 'PRESENTADA' || this.newStatus === 'APROBADA') && this.orderProducts.length === 0)
          alert('Debes primero agregar productos para realizar el cambio de estado.');
        else if(this.order.commercialState === 'APROBADA' && this.newStatus === 'RECHAZADA')
          alert('Una órden aprobada no se puede rechazar.');
        else
          this.overlayChangeStatus = true;
      }
    },
    changeOperationalState(newStatus) {
      if(this.order.operationalState !== newStatus) {
        this.newStatus = newStatus;
        this.statusTypeToChange = 'OPERATIONAL';
        this.overlayChangeStatus = true;
      }
    },
    changeAccountableState(newStatus) {
      if(this.order.accountableState !== newStatus) {
        this.newStatus = newStatus;
        this.statusTypeToChange = 'ACCOUNTABLE';
        this.overlayChangeStatus = true;
      }
    },
    isLastProduct(productId) {
      if(this.orderProducts.length === 0) return true;
      return productId === this.orderProducts[this.orderProducts.length - 1].id;
    },
    addProduct() {
      this.overlayProductType = true;
    },
    deleteProduct(product) {
      this.selectedProduct = product;
      this.overlayDeleteProduct = true;
    },
    productUpdated() {
      this.updatePricing();
      this.getOrderProducts();
    },
    seeProductItems(product) {
      this.selectedProduct = product;
      this.overlayProductItems = true;
    },
    editProduct(product) {
      this.selectedProduct = product;
      if(!product.thirdParty)
        this.overlayEditProduct = true;
      else
        this.overlayEditThirdPartyProduct = true;
    },
    duplicateProduct(product) {
      this.selectedProduct = product;
      this.overlayDuplicateProduct = true;
    },
    productTypeChoose(type) {
      if (type === 'Crear Producto Personalizado')
        this.overlayNewCustomProduct = true;
      else if(type === 'Llamar Producto Estandarizado')
        this.overlayStandardizedProduct = true;
      else
        this.overlayThirdPartyProduct = true;

      this.overlayProductType = false;
    },
    saveOrder() {
      alert('¡Estado de la órden fue guardada satisfactoriamente!\nPuede continuar luego.');
      this.close();
    },
    formatText(string, maxLength) {
      return (string.length > maxLength)? `${String(string).substring(0, maxLength - 3)}...` : string;
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
