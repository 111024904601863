<template>
  <v-container fluid>
    <v-row style="height: 91vh" align="center" justify="center">
      <v-col class="text-center" cols="12" lg="4" md="8" sm="12" xl="4">
        <h1>Recuperar Cuenta</h1>
        <p>
          Ingrese su email para poder verificar su cuenta.
          <br>Un enlace de confirmación le permitirá reiniciar su contraseña.
        </p>
        <v-text-field v-model="email" :rules="emailRule" label="Email" outlined placeholder="diana@dianadigital.com"/>
        <v-btn @click="checkLostPassword">Validar »</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LostPassword",
  data() {
    return {
      email: '',
      emailRule: [
        e => !!e || 'Email es necesario',
        e => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e) || 'Ingrese email válido',
      ],
    };
  },
  methods: {
    checkLostPassword() {

    },
  }
}
</script>

<style scoped>

</style>
