<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>{{ (creating)? 'Nuevo Producto Estandarizado' : 'Editando Producto Estandarizado'}}</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1">Nombre</v-stepper-step>
          <v-stepper-step step="2">Tipo de Impresión</v-stepper-step>
          <v-stepper-step step="3">Items del Producto</v-stepper-step>
          <v-stepper-step step="4">Confirmación</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form v-model="validFirst">
              <p v-if="$store.state.desktop" class="text-center">Características del producto</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-text-field label="Nombre del Producto" placeholder="Ej. Tarjetas de presentación"
                                  v-model="name" :rules="nameRules" counter="40" outlined/>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn :disabled="!validFirst" @click="nextStep">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form v-model="validSecond">
              <p v-if="$store.state.desktop" class="text-center">Selecciona un tipo de impresión</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-autocomplete :items="printTypes" item-value="id" item-text="name"
                                    label="Tipo de Impresión" placeholder="Ej. Corte Láser"
                                    v-model="printType" :rules="printTypeRules" outlined/>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validSecond">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form v-model="validThird">
              <p v-if="$store.state.desktop" class="text-center">Selecciona los ítems del producto</p>
              <v-container fluid>
                <v-row dense align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10"
                         class="d-flex align-center justify-center" v-bind:key="item.id" v-for="item in itemsSelected">
                    <v-autocomplete :items="$store.state.items.filter((partialItem) => partialItem.category !== 'IMPRESION')"
                                    item-value="id" item-text="name"
                                    :label="`Item N° ${item.position}`" placeholder="Ej. Poliestireno 100"
                                    v-model="item.id" :rules="itemRules" @change="verifyNotSameItem(item)" outlined/>
                    <v-icon v-if="verifyDuplicated(item.id)" class="mt-n6 ml-2" color="secondary" small>fa-exclamation-triangle</v-icon>
                    <v-icon v-if="item.position !== 1 && item.position === itemsSelected.length"
                            class="mt-n5 ml-2" @click="deleteLastItem">fa-trash</v-icon>
                  </v-col>
                </v-row>
                <v-row class="mb-5 mt-n3" align="center" justify="center">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <v-btn :disabled="verifyCannotAddMore()" width="100%" class="add-item-button" @click="addItem" small>
                      Agregar Item <v-icon class="ml-2" small>fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn @click="nextStep" :disabled="!validThird">Siguiente »</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-form>
              <p v-if="$store.state.desktop" class="text-center">Verifica el producto nuevo:</p>
              <v-container fluid>
                <v-row dense align="center" justify="start">
                  <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                    <p class="my-0">Nombre del Producto</p>
                    <h3>{{ name }}</h3>

                    <p class="my-0 mt-5">Tipo de Impresión del Producto</p>
                    <h3>{{ printTypeName }}</h3>

                    <p class="my-0 mt-5">Items del Producto</p>
                    <h3 class="mb-5">{{ itemsSelected.length }} Items</h3>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn @click="backStep" class="mr-3">« Atrás</v-btn>
                  <v-btn v-if="creating" @click="addProduct">
                    Crear Producto <v-icon class="ml-2" small>fa-plus</v-icon>
                  </v-btn>
                  <v-btn v-else @click="editProduct">
                    Editar Producto <v-icon class="ml-2" small>fa-check</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/product';
import { mapMutations } from 'vuex';

export default {
  name: "SaveStandardizedProduct",
  props: {
    creating: Boolean,
    product: Object,
  },
  data() {
    return {
      step: 1,
      name: '',
      printType: 0,
      originalItems: [],
      itemsSelected: [
        { position: 1, id: 0 },
      ],
      printTypes: [],

      // Validations
      validFirst: false,
      validSecond: false,
      validThird: false,
      nameRules: [
          n => !!n || 'Nombre del producto es obligatorio',
          n => (n && n.length <= 40) || 'Nombre debe ser menor a 40 caracteres',
      ],
      printTypeRules: [
          p => !!p || 'Tipo de impresión es obligatoria',
      ],
      itemRules: [
          i => !!i || 'Item es requerido',
          i => (i && !this.verifyDuplicated(i)) || 'Item está duplicado',
      ]
    };
  },
  computed: {
    printTypeName() {
      return (this.printType !== 0)? this.$store.state.items.find((print) => print.id === this.printType).name : '';
    }
  },
  mounted() {
    this.setDefaults();
    if(!this.creating)
      this.setDefaultsByEditing();
  },
  methods: {
    setDefaults() {
      this.printTypes = this.$store.state.items.filter((item) => item.category === 'IMPRESION');
      this.printType = this.printTypes[0].id;
    },
    setDefaultsByEditing() {
      this.name = this.product.name;
      Repository.getItemsFromStandardizedProduct(this.product.id).then((response) => {
        if(response.status < 400) {
          this.originalItems = response.data;
          this.itemsSelected = [];
          for (let i = 1, realPosition = 1; i - 1 < this.originalItems.length; i++, realPosition++) {
            let item = this.originalItems[i - 1];
            if(item.category === 'IMPRESION') {
              this.printType = item.id;
              realPosition--;
            } else
              this.itemsSelected.push({ position: realPosition, id: item.id  });
          }
        }
      });
    },
    backStep() {
      this.step--;
    },
    nextStep() {
      this.step++;
    },
    addItem() {
      this.itemsSelected.push({ position: (this.itemsSelected.length + 1), id: 0 });
    },
    verifyDuplicated(itemEvaluatedId) {
      return this.itemsSelected.filter((item) => item.id === itemEvaluatedId).length > 1;
    },
    verifyNotSameItem(itemChanged) {
      if(this.verifyDuplicated(itemChanged.id)) {
        alert('No puede escoger items repetidos.\nPor favor intente de nuevo.');
        if(itemChanged.position === this.itemsSelected.length)
          this.itemsSelected.pop();
      }
    },
    verifyCannotAddMore(){
      return this.itemsSelected[this.itemsSelected.length - 1].id === 0;
    },
    deleteLastItem() {
      this.itemsSelected.pop();
    },
    ...mapMutations(['setStandardizedProducts']),
    addProduct() {
      let product = {
        name: this.name,
      }

      let itemsId = [this.printType];
      this.itemsSelected.forEach((item) => itemsId.push(item.id));

      console.log(itemsId);

      Repository.addStandardizedProduct(product).then((response) => {
        if(response.status < 400) {
          let itemsData = {
            productId: response.data.id,
            items: itemsId
          }

          Repository.assignItemsToStandardizedProduct(itemsData).then((secondResponse) => {
            if(secondResponse.status < 400) {
              alert(`¡${this.name} fue ${(this.creating)? 'creado': 'editado'} satisfactoriamente!`);
              Repository.getAllStandardizedProducts().then((thirdResponse) => { if(thirdResponse.status < 400) this.setStandardizedProducts(thirdResponse.data); });
              this.close();
            }
          });
        }
      });
    },
    editProduct() {
      Repository.deleteStandardizedProduct(this.product.id).then((response) => {
        if(response.status < 400)
          this.addProduct();
      });
    },
    close() {
      if(this.$store.state.desktop)
        this.$emit('close');
      else
        this.$router.push('/productos');
    },
  }
}
</script>

<style scoped>
.add-item-button {
  background-color: #3700b6 !important;
  color: #ffffff !important;
}
</style>
