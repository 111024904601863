<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h3>Listado de Órdenes</h3>
        <v-btn class="ml-5" dark small @click="addOrder">Agregar
          <v-icon class="ml-2" small>fa-plus</v-icon>
        </v-btn>
        <v-spacer/>
        <v-text-field v-model="search"
                      :placeholder="($store.state.desktop)? 'Busca por id o nombre de cliente' : 'Buscar órdenes'"
                      prepend-icon="fa-search"/>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="10" xl="10" :class="($store.state.desktop)?'d-flex align-center justify-center' : ''">
          <p :class="($store.state.desktop)? 'mr-2 mt-n2' : ''">Filtrando</p>
          <v-select :style="($store.state.desktop)? 'max-width: 25%;' : ''" :items="categories"
                    v-model="categorySelected" @change="categoryChanged" dense outlined/>
          <p :class="($store.state.desktop)? 'mx-2 mt-n2' : ''">{{ (categorySelected !== 'TODAS')? 'y' : 'las órdenes' }}</p>
          <v-select v-if="categorySelected !== 'TODAS'" :style="($store.state.desktop)? 'max-width: 25%;' : ''"
                    :items="states" v-model="stateSelected" @change="stateChanged" dense outlined/>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-checkbox v-model="seeMyOrders" :label="(seeMyOrders)? 'Viendo mis órdenes' : 'Ver únicamente mis órdenes'" @change="seeMyOrdersChanged"/>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="filteredOrders" :search="search" no-data-text="No hay resultados. Intenta con algún otro filtro.">
            <template v-slot:item.title="{ item }">
              <p class="my-0" style="font-size: 14px">{{ cutString(item.title, 35) }}</p>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <p class="my-0" style="font-size: 14px">{{ getDateFormatted(item.createdAt) }}</p>
            </template>
            <template v-slot:item.state="{ item }">
              <v-chip color="primary" small>
                <p class="my-0" style="font-size: 12px">{{ item.commercialState.replaceAll('_', ' ') }}</p>
              </v-chip>
              <v-chip v-if="item.operationalState !== null" color="secondary" class="ml-1" small >
                <p class="my-0" style="font-size: 12px">{{ item.operationalState.replaceAll('_', ' ') }}</p>
              </v-chip>
              <v-chip v-if="item.accountableState !== null" color="accent" text-color="black" class="ml-1" small>
                <p class="my-0" style="font-size: 12px">{{ item.accountableState.replaceAll('_', ' ') }}</p>
              </v-chip>
            </template>
            <!--template v-slot:item.client="{ item }">
              <p class="my-0" style="font-size: 14px">{{ getClientName(item.client) }}</p>
            </template!-->
            <template v-slot:item.totalPrice="{ item }">
              <p class="my-0" style="font-size: 14px">${{ new Intl.NumberFormat().format(Number(getFullTotalPrice(item).toFixed(0))) }}</p>
            </template>
            <template v-slot:item.seller="{ item }">
              <p class="my-0" style="font-size: 14px">{{ getSellerName(item.seller) }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="icon" small @click="orderDetails(item)">fa-eye</v-icon>
              <!--v-icon class="icon ml-3" small @click="duplicateOrder(item)">fa-copy</v-icon!-->
              <!--v-icon class="icon" small @click="deleteOrder(item)">fa-trash</v-icon!-->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "OrdersList",
  data() {
    return {
      search: '',
      seeMyOrders: false,
      totalOrders: [],
      filteredOrders: [],
      headers: [
        { text: 'ID', value: 'id', align: 'left', sortable: true, filterable: true },
        { text: 'Título', value: 'title', align: 'left', sortable: false, filterable: false },
        { text: 'Estado', value: 'state', align: 'left', sortable: false, filterable: false },
        { text: 'Cliente', value: 'clientName', align: 'left', sortable: true, filterable: true },
        { text: 'Vendedor', value: 'seller', align: 'left', sortable: true, filterable: false },
        { text: 'Valor Total', value: 'totalPrice', align: 'left', sortable: true, filterable: false },
        { text: 'Creada', value: 'createdAt', align: 'left', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', align: 'right', sortable: false, filterable: false },
      ],

      categories: [
        'TODAS',
        'COMERCIAL',
        'OPERACIONAL',
        'CONTABLE',
      ],
      states: ['TODAS'],
      categorySelected: 'TODAS',
      stateSelected: 'TODAS',
    };
  },
  watch: {
    '$store.state.orders': function () {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.totalOrders = [];
      this.$store.state.orders.forEach((order) => {
        let finalOrder = order;
        finalOrder.clientName = this.getClientName(finalOrder.client);
        this.totalOrders.push(finalOrder);
      });

      this.filteredOrders = this.totalOrders;
    },
    seeMyOrdersChanged() {
      this.categoryChanged();
    },
    getDateFormatted(dateString) {
      let date = new Date(dateString);
      return `${this.$store.state.constants.months[date.getMonth()]} ${String(date.getDate()).padStart(2, '0')}/${String(date.getFullYear()).substring(2, 4)}
        ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },
    getClientName(client) {
      if(client.clientType === 'PERSONA_JURIDICA')
        return this.$store.state.businesses.find((business) => business.client.id === client.id).commercialName;

      let userFound = this.$store.state.contacts.find((contact) => contact.client.id === client.id).user;
      return `${userFound.firstName} ${userFound.lastName}`;
    },
    getSellerName(seller) {
      return seller.user.firstName;
    },
    getFullTotalPrice(order) {
      let total = Number(order.totalPrice);
      if(order.bonusFee !== null) total += Number(order.bonusFee);
      if(order.discountFee !== null) total -= Number(order.discountFee);
      if(order.deliveryFee !== null) total += Number(order.deliveryFee);
      return Number(total);
    },
    categoryChanged() {
      this.states = [];
      if(this.categorySelected !== 'TODAS') {
        Array.prototype.push.apply(this.states,
            (this.categorySelected === 'COMERCIAL') ? this.$store.state.constants.commercialStates :
                (this.categorySelected === 'OPERACIONAL') ? this.$store.state.constants.operationalStates : this.$store.state.constants.accountableStates);
        this.stateSelected = this.states[0];
        this.stateChanged();
      }
      else
        this.filteredOrders = (this.seeMyOrders)? this.totalOrders.filter((order) => order.seller.user.id === this.$store.state.currentUser.id) : this.totalOrders;
    },
    stateChanged() {
      if(this.stateSelected !== 'TODAS')
        this.filteredOrders = (this.categorySelected === 'COMERCIAL')? this.totalOrders.filter((order) => order.commercialState === this.stateSelected) :
            (this.categorySelected === 'OPERACIONAL')? this.totalOrders.filter((order) => order.operationalState !== null && order.operationalState === this.stateSelected) :
                this.totalOrders.filter((order) => order.accountableState !== null && order.accountableState === this.stateSelected);

      if(this.seeMyOrders)
        this.filteredOrders = this.filteredOrders.filter((order) => order.seller.user.id === this.$store.state.currentUser.id);
    },
    addOrder() {
      this.$emit('addOrder');
    },
    orderDetails(order) {
      this.$emit('orderDetails', order);
    },
    duplicateOrder(order) {
      this.$emit('duplicateOrder', order);
    },
    deleteOrder(order) {
      this.$emit('deleteOrder', order);
    },
    capitalize(string) {
      return (string[0].toUpperCase() + string.slice(1).toLowerCase()).replaceAll('_', ' ');
    },
    cutString(string, maxLength) {
      return (string.length > maxLength)? `${String(string).substring(0, maxLength - 3)}...` : string;
    },
  },
}
</script>

<style scoped>

</style>
