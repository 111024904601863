<template>
  <v-card :max-width="($store.state.desktop)? '35vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Cambiar Estado de Órden</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <v-container fluid>
        <v-row align="center" class="mb-3" justify="center">
          <v-icon class="mr-7 mt-n4" color="secondary">fa-sync</v-icon>
          <p>
            ¿Desea cambiar el estado de la órden a<br>
            <b style="color: #FF1A6C">{{ newStatus }}</b>?
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn @click="changeStatus">Cambiar Estado</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/order';
export default {
  name: "ChangeState",
  props: {
    order: Object,
    newStatus: String,
    statusType: String
  },
  methods: {
    changeStatus() {
      let editData = {
        orderId: this.order.id,
        newStatus: this.newStatus,
      };

      switch (this.statusType) {
        case 'COMMERCIAL':
          Repository.editCommercialState(editData).then((response) => {
            if(response.status < 400) {
              alert(`¡Estado fue cambiado a ${this.newStatus}!`);
              this.order.commercialState = this.newStatus;
              if(this.newStatus === 'APROBADA')
                this.order.operationalState = 'REVISION_ARCHIVOS';
              this.close();
            }
          });
          break;
        case 'OPERATIONAL':
          Repository.editOperationalState(editData).then((response) => {
            if(response.status < 400) {
              alert(`¡Estado fue cambiado a ${this.newStatus}!`);
              this.order.operationalState = this.newStatus;
              this.close();
            }
          });
          break;
        default:
          Repository.editAccountableState(editData).then((response) => {
            if(response.status < 400) {
              alert(`¡Estado fue cambiado a ${this.newStatus}!`);
              this.order.accountableState = this.newStatus;
              this.close();
            }
          });
          break;
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
