<template>
  <v-card :max-width="($store.state.desktop)? '42vw' : ''" light min-width="30vw" outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Detalles del cliente</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="overflow-y-auto pa-5" style="max-height: 60vh !important;">
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Nombre</p>
            <h4>{{ getClientName() }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Identificación</p>
            <h4>{{ client.identificationType }} {{ client.identificationNumber }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Correo Facturación</p>
            <h4>{{ client.invoicingEmail }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Dirección</p>
            <h4>{{ client.address }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Departamento</p>
            <h4>{{ client.state }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Ciudad</p>
            <h4>{{ client.city }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Responsable de IVA</p>
            <h4>{{ `${client.vatResponsible? 'Si' : 'No' } es responsable de IVA` }}</h4>
          </v-col>
          <v-col v-if="client.creditTerm !== null" cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Solicitud de Crédito</p>
            <h4>{{ client.creditTerm }} {{ (client.approvedCredit)? '(Aprobada)' : '(No aprobada)' }}</h4>
          </v-col>
        </v-row>
        <v-divider v-if="isBusiness" class="my-7"/>
        <v-row v-if="isBusiness">
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Razón Social de la Empresa</p>
            <h4>{{ business.businessName }}</h4>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xl="6">
            <p class="mb-0">Sector de la Empresa</p>
            <h4>{{ business.sector }}</h4>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ClientDetails",
  props: {
    client: Object,
  },
  data() {
    return {
      isBusiness: false,
      business: null,
    };
  },
  mounted() {
    this.verifyBusiness();
  },
  methods: {
    verifyBusiness() {
      this.isBusiness = this.client.clientType === 'PERSONA_JURIDICA';
      if (this.isBusiness) {
        this.business = this.$store.state.businesses.find((business) => business.client.id === this.client.id);
      }
    },
    getClientName() {
      if(this.isBusiness)
        return this.business.commercialName;
      let userFromClient = this.$store.state.contacts.find((contact) => contact.client.id === this.client.id).user;
      return `${userFromClient.firstName} ${userFromClient.lastName}`;
    },
    close() {
      this.$emit('close');
    }
  },
}
</script>

<style scoped>

</style>
