var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"outlined":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h3',[_vm._v("Listado de Items")]),(_vm.fullAccess)?_c('v-btn',{staticClass:"ml-5",attrs:{"dark":"","small":""},on:{"click":_vm.addItem}},[_vm._v("Agregar "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fa-plus")])],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"placeholder":(_vm.$store.state.desktop)? 'Busca por id, nombre, escala de medida o categoría' : 'Buscar items',"prepend-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.measureScale",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-0",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.formatText(item.measureScale)))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getCategoryColor(item.category),"small":""}},[_c('p',{staticClass:"my-0",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.formatText(item.category)))])])]}},{key:"item.unitValue",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-0",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.getUnitValueText(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fa-edit")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fa-trash")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }