<template>
  <v-navigation-drawer app permanent>
    <v-list class="my-3">
      <div class="d-flex align-center justify-center">
        <v-avatar size="70">
          <v-img :src="($store.state.currentUser.imageUrl) !== null ? $store.state.currentUser.imageUrl : 'https://www.seekpng.com/png/detail/428-4287240_no-avatar-user-circle-icon-png.png'"></v-img>
        </v-avatar>
      </div>

      <h2 class="text-center">{{ $store.state.currentUser.firstName }}</h2>
      <p style="color: #7e7e7e; font-size: 14px;" class="text-center mb-1">{{ $store.state.currentUser.email }}</p>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item :style="(item.route === selectedItem)?'border: 1px solid #3700b6' : ''" v-bind:key="item.text" v-for="item in items" @click="goToRoute(item.route)" link>
        <v-list-item-icon class="mr-3">
          <v-icon :color="(item.route === selectedItem)? 'primary' : ''" small>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title :style="(item.route === selectedItem)? 'color: #3700b6;' : ''">{{ item.text }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout" link>
        <v-list-item-icon class="mr-3">
          <v-icon small>fa-power-off</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cerrar Sesión</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="d-flex align-center justify-center mb-7">
        <v-img position="center center" max-width="91" contain
               src="https://firebasestorage.googleapis.com/v0/b/di-lab-front.appspot.com/o/Logo%20Diana%20Negro.png?alt=media&token=48c19f42-a1d7-4d70-85ed-3524a1508cb1"/>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex';
import RoleAccess from "@/specs/roleAccess";
export default {
  name: "SideNavBar",
  data() {
    return {
      fullAccess: false,
      items: [
        { text: 'Inicio', icon: 'fa-home', route: '/inicio' },
        { text: 'Órdenes', icon: 'fa-boxes', route: '/ordenes' },
        { text: 'Clientes', icon: 'fa-user-friends', route: '/clientes' },
        { text: 'Contactos', icon: 'fa-users', route: '/contactos' },
        { text: 'Productos', icon: 'fa-box', route: '/productos' },
        { text: 'Items', icon: 'fa-cube', route: '/items' },
      ],
      selectedItem: '/inicio',
    };
  },
  methods: {
    verifyFullAccess() {
      this.fullAccess = RoleAccess.find((id) => id === this.$store.state.currentUser.id) !== undefined;
      if(this.fullAccess)
        this.headers.push({ text: 'Acciones', value: 'actions', align: 'right', sortable: false, filterable: false });
    },
    goToRoute(route) {
      this.selectedItem = route;
      this.$router.push(route);
    },
    ...mapMutations(['logoutSession']),
    logout() {
      this.logoutSession();
      this.$router.push('/');
    },
  }
}
</script>

<style scoped>

</style>
