<template>
  <v-bottom-navigation background-color="primary" app>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col class="button-navigation" v-bind:key="item.text" v-for="item in items" @click="goToRoute(item.route)">
          <v-icon style="width: 100%" small :color="(item.route === selectedItem)? 'accent' : 'white'">
            {{ item.icon }}
          </v-icon>
          <p class="mb-n1 text-center" :style="`font-size: 13px; color: ${(item.route === selectedItem)? '#FFF500' : '#FFFFFF'}`">
            {{ item.text }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "MobileNavBar",
  data() {
    return {
      items: [
        { text: 'Inicio', icon: 'fa-home', route: '/inicio' },
        { text: 'Menu', icon: 'fa-bars', route: '/menu' },
        { text: 'Cerrar', icon: 'fa-power-off', route: '/logout' },
      ],
      selectedItem: '/inicio',
    };
  },
  methods: {
    goToRoute(route) {
      if(route === '/logout')
        this.logout();
      else {
        this.selectedItem = route;
        this.$router.push(route);
      }
    },
    ...mapMutations(['logoutSession']),
    logout() {
      this.logoutSession();
      this.$router.push('/');
    },
  },
}
</script>

<style scoped>
.button-navigation {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #7e7e7e;
}
</style>
