<template>
  <v-card min-width="30vw" light outlined>
    <v-card-title style="background-color: #d4d4d4">
      <h2>Solicitudes de Créditos</h2>
      <v-spacer/>
      <v-icon class="icon" @click="close">fa-times</v-icon>
    </v-card-title>
    <v-card-text class="pa-5">
      <p v-if="$store.state.desktop" class="text-center">Solicitudes de crédito pendientes:</p>
      <v-data-table :items="requests" :headers="headers">
        <template v-slot:item.name="{ item }">
          <p class="my-0">{{ getClientName(item) }}</p>
        </template>
        <template v-slot:item.creditTerm="{ item }">
          <p class="my-0">{{ item.creditTerm.replaceAll('_', ' ') }}</p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-chip color="green" class="mr-2" text-color="white" @click="approveRequest(item)" small>Aprobar</v-chip>
          <v-chip color="secondary" @click="denyRequest(item)" small>Denegar</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Repository from '../../../repositories/client';
import { mapMutations } from 'vuex';
export default {
  name: "CreditApprovals",
  props: {
    requests: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Cliente', value: 'name', align: 'left', sortable: true },
        { text: 'Tipo de Crédito', value: 'creditTerm', align: 'left', sortable: false },
        { text: 'Aprobación', value: 'actions', align: 'right', sortable: false },
      ],
    };
  },
  methods: {
    getClientName(client) {
      if(client.clientType === 'PERSONA_JURIDICA')
        return this.$store.state.businesses.find((business) => business.client.id === client.id).businessName;

      let userFound = this.$store.state.contacts.find((contact) => contact.client.id === client.id).user;
      return `${userFound.firstName} ${userFound.lastName}`;
    },
    ...mapMutations(['setClients']),
    approveRequest(client) {
      let creditData = {
        clientId: client.id,
        approvalResponse: true,
      };

      Repository.confirmCreditResponse(creditData).then((response) => {
        if(response.status < 400 && response.data) {
          alert(`¡Solicitud de crédito para ${this.getClientName(client)} fue aprobada!`);
          Repository.getAllClients().then((secondResponse) => { if(secondResponse.status < 400) this.setClients(secondResponse.data);});
        }
      });
    },
    ...mapMutations(['setClients']),
    denyRequest(client) {
      let creditData = {
        clientId: client.id,
        approvalResponse: false,
      };

      Repository.confirmCreditResponse(creditData).then((response) => {
        if(response.status < 400 && response.data) {
          alert(`¡Solicitud de crédito para ${this.getClientName(client)} fue denegada!`);
          Repository.getAllClients().then((secondResponse) => { if(secondResponse.status < 400) this.setClients(secondResponse.data);});
        }
      });
    },
    close() {
      this.$emit('close');
    }
  },
}
</script>

<style scoped>

</style>
