const pricingSpecs = [
    // Big Format Print Items
    { itemId: 1, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},
    { itemId: 2, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},
    { itemId: 3, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},
    { itemId: 4, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},
    { itemId: 5, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},
    { itemId: 20, minSquareCms: 10000, discountScales: [ { fromCms: 50000, rate: 0.95 }, { fromCms: 100000, rate: 0.9025 }, { fromCms: 200000, rate: 0.8573 }, { fromCms: 400000, rate: 0.8145 }, { fromCms: 600000, rate: 0.7737 }, { fromCms: 800000, rate: 0.7350 } ]},

    // Small Format Print Items
    { itemId: 6, minSquareCms: 1504, discountScales: [ { fromCms: 15040, rate: 0.95 }, { fromCms: 75200, rate: 0.9025 }, { fromCms: 150400, rate: 0.8573 }, { fromCms: 300800, rate: 0.8145 }, { fromCms: 752000, rate: 0.7737 }, { fromCms: 1504000, rate: 0.7350 } ]},
    { itemId: 7, minSquareCms: 1204, discountScales: [ { fromCms: 12040, rate: 0.95 }, { fromCms: 60200, rate: 0.9025 }, { fromCms: 120400, rate: 0.8573 }, { fromCms: 240800, rate: 0.8145 }, { fromCms: 602000, rate: 0.7737 }, { fromCms: 1204000, rate: 0.7350 } ]},
];

export default pricingSpecs;
