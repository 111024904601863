import axios from 'axios';
import store from '../store'
import router from "../router";

class ContactRepository {

    constructor(){
        this.basePath = store.state.backend;
    }

    getAllContacts() {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/contact-details`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getSellerFromContact(contactId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/contact-details/seller-from-contact/${contactId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getAllSellersFromClient(clientId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/contact-details/from-client/${clientId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    getAllContactsFromSeller(sellerId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.get(`${this.basePath}/contact-details/from-seller/${sellerId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    addContact(contact) {
        let headers = {'Authorization': store.state.token};
        let response = axios.post(`${this.basePath}/contact-details`, contact, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    updateContact(contact) {
        let headers = {'Authorization': store.state.token};
        let response = axios.put(`${this.basePath}/contact-details`, contact, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }

    deleteContact(contactId) {
        let headers = {'Authorization': store.state.token};
        let response = axios.delete(`${this.basePath}/contact-details/${contactId}`, {headers: headers});
        response.catch((error) => { if(error.response.status === 401) { store.commit('clearData'); router.push('/'); } });  // Go to login to re-authenticate
        return response;
    }
}

export default new ContactRepository();
