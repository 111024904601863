<template>
  <v-card class="pa-5" outlined>
    <v-container fluid>
      <v-row align="center" justify="center">
        <h3>Listado de Productos</h3>
        <v-btn v-if="fullAccess" class="ml-5" dark small @click="addProduct">Agregar
          <v-icon class="ml-2" small>fa-plus</v-icon>
        </v-btn>
        <v-spacer/>
        <v-text-field v-model="search" :placeholder="($store.state.desktop)? 'Busca por nombre de producto' : 'Buscar productos'" prepend-icon="fa-search"/>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-data-table :headers="headers" :items="$store.state.standardizedProducts" :search="search">
            <template v-slot:item.items="{ item }">
              <v-chip small @click="seeItems(item)">
                <p style="font-size: 14px;" class="my-0">Ver Items</p>
                <v-icon class="ml-2" x-small>fa-cube</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-3" @click="editProduct(item)" small>fa-edit</v-icon>
              <v-icon @click="deleteProduct(item)" small>fa-trash</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import RoleAccess from '../../../specs/roleAccess';
export default {
  name: "ProductsList",
  data() {
    return {
      fullAccess: false,
      search: '',
      headers: [
        { text: 'Nombre del Producto', value: 'name', align: 'left', sortable: true, filterable: true },
        { text: 'Items', value: 'items', align: 'left', sortable: true, filterable: true },
      ],
    };
  },
  watch: {
    '$store.state.standardizedProducts': function () {
    },
  },
  mounted() {
    this.verifyFullAccess();
  },
  methods: {
    verifyFullAccess() {
      this.fullAccess = RoleAccess.find((id) => id === this.$store.state.currentUser.id) !== undefined;
      if(this.fullAccess)
        this.headers.push({ text: 'Acciones', value: 'actions', align: 'right', sortable: false, filterable: false });
    },
    addProduct() {
      this.$emit('addProduct');
    },
    seeItems(product) {
      this.$emit('seeItems', product);
    },
    getPrintType(printTypeId) {
      return this.$store.state.items.find((item) => item.id === printTypeId);
    },
    editProduct(product) {
      this.$emit('editProduct', product);
    },
    deleteProduct(product) {
      this.$emit('deleteProduct', product);
    },
  }
}
</script>

<style scoped>

</style>
